import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';
import MailsService from '../../../../services/MailsService';

/**
 * DeleteMailRecipientModal Component
 *
 * This component displays a modal for confirming the deletion of an mail recipient.
 * It takes `open`, `onClose`, `mailRecipientId`, and `onDelete` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose (function): Function to close the modal.
 * - mailRecipientId (number): The ID of the mail recipient to delete.
 * - onDelete (function): Function to handle the deletion after success.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const DeleteMailRecipientModal = ({ open, onClose, mailRecipientId, onDelete }) => {
    // State to handle error messages
    const [error, setError] = useState(null);

    /**
     * Handles the deletion confirmation
     *
     * @returns {void}
     */
    const handleConfirmDelete = async () => {
        if (mailRecipientId) {
            try {
                // Delete mail recipient
                await MailsService.deleteMailRecipient(mailRecipientId);

                // Trigger the onDelete callback
                onDelete();

                // Close the modal after the operation
                onClose();
            } catch (error) {
                setError(error?.message || "Une erreur inattendue est survenue");
            }
        }
    };

    // Return null if the modal is not open or if there is no mail recipient ID
    if (!open || !mailRecipientId) return null;

    // Render the modal
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Confirmer la suppression'}
            handleSave={handleConfirmDelete}
            handleSaveLabel="Confirmer la suppression"
            onCloseLabel={'Annuler'}
        >
            {error ? (
                <InfoBox type="error" text={error} disabledCloseBtn={true} />
            ) : (
                <InfoBox
                    type="warning"
                    text={
                        <p>Êtes-vous sûr de vouloir supprimer ce destinataire de mail ? Cette action est irréversible.</p>
                    }
                    disabledCloseBtn={true}
                />
            )}
        </CustomModal>
    );
};

// Prop types for the DeleteMailRecipientModal component
DeleteMailRecipientModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    mailRecipientId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onDelete: PropTypes.func.isRequired,
};

export default DeleteMailRecipientModal;
