// cameraModule/hooks/useImageProcessing.js

import { Geolocation } from '@capacitor/geolocation';
import Pica from 'pica';

/**
 * Custom hook to handle image processing tasks such as adding timestamps, geolocation,
 * and compressing images. This hook can be used in any React component to manage
 * image processing functionality in an accessible and reusable way.
 *
 * @returns {Object} An object containing functions for image processing and a loading state.
 */
export const useImageProcessing = () => {
    /**
     * Draws text on the given canvas context.
     *
     * @param {CanvasRenderingContext2D} ctx - The canvas context to draw on.
     * @param {string} text - The text to be drawn on the canvas.
     * @param {number} x - The x-coordinate where the text should be positioned.
     * @param {number} y - The y-coordinate where the text should be positioned.
     * @param {string} [bgColor='rgba(0, 0, 0, 0.5)'] - The background color for the text (optional).
     */
    const drawText = (ctx, text, x = 0, y = 0, bgColor = 'rgba(0, 0, 0, 0.5)') => {
        let fontSize = Math.floor(ctx.canvas.width * 0.03); // Dynamic font size based on canvas width
        ctx.font = `${fontSize}px Arial`;
        let textWidth = ctx.measureText(text).width;

        // Ensure text fits within the canvas by reducing font size if needed
        while (textWidth > ctx.canvas.width - 40 && fontSize > 10) {
            fontSize--;
            ctx.font = `${fontSize}px Arial`;
            textWidth = ctx.measureText(text).width;
        }

        const rectHeight = fontSize + 30;
        ctx.fillStyle = bgColor; // Background for better text readability
        ctx.fillRect(x, y - rectHeight, ctx.canvas.width, rectHeight);
        ctx.fillStyle = 'white';
        ctx.fillText(text, x + 20, y - 20); // Draw the text on the canvas
    };

    /**
     * Gets the current timestamp in a readable format.
     *
     * @returns {string} The current timestamp.
     */
    const getTimestamp = () => {
        return `${new Date().toLocaleString()}`;
    };

    /**
     * Retrieves the device's geolocation coordinates.
     *
     * @returns {Promise<string>} A promise resolving to a string representing latitude and longitude.
     */
    const getGeolocation = async () => {
        try {
            const locationPromise = Geolocation.getCurrentPosition({
                timeout: 5000, // Set timeout to 5 seconds
            });

            // Create a timeout promise that rejects after 5 seconds
            const timeoutPromise = new Promise((_, reject) => 
                setTimeout(() => reject(new Error('Request timed out')), 5000)
            );

            // Use Promise.race to return the first promise that resolves or rejects
            const location = await Promise.race([locationPromise, timeoutPromise]);

            return `Lat: ${location.coords.latitude.toFixed(6)}, Lon: ${location.coords.longitude.toFixed(6)}`;
        } catch (error) {
            console.error('Geolocation error: ', error);
            return 'Géolocalisation non disponible';
        }
    };

    /**
     * Adds a timestamp and geolocation text to the base64 image.
     *
     * @param {string} base64Image - The base64 encoded image string.
     * @returns {Promise<string>} A promise resolving to the modified base64 image.
     */
    const addTimestampAndGeolocation = async (base64Image) => {

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.src = `${base64Image}`;

        return new Promise(async (resolve) => {
            img.onload = async () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                const timestamp = getTimestamp();
                const geolocation = await getGeolocation();

                const textToDraw = `CTRDrako | ${timestamp} | ${geolocation}`;
                drawText(ctx, textToDraw, 0, canvas.height); // Draw the text at the bottom of the image

                resolve(canvas.toDataURL('image/jpeg'));
            };
        });
    };

    /**
     * Adds only the timestamp to the base64 image.
     *
     * @param {string} base64Image - The base64 encoded image string.
     * @returns {Promise<string>} A promise resolving to the modified base64 image.
     */
    const addTimestamp = async (base64Image) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.src = `${base64Image}`;

        return new Promise((resolve) => {
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                const timestamp = getTimestamp();
                drawText(ctx, `CTRDrako | ${timestamp}`, 0, canvas.height); // Draw timestamp

                resolve(canvas.toDataURL('image/jpeg'));
            };
        });
    };

    /**
     * Adds only the geolocation to the base64 image.
     *
     * @param {string} base64Image - The base64 encoded image string.
     * @returns {Promise<string>} A promise resolving to the modified base64 image.
     */
    const addGeolocation = async (base64Image) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.src = `${base64Image}`;

        return new Promise(async (resolve) => {
            img.onload = async () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                const geolocation = await getGeolocation();
                drawText(ctx, `CTRDrako | ${geolocation}`, 0, canvas.height); // Draw geolocation

                resolve(canvas.toDataURL('image/jpeg'));
            };
        });
    };

    /**
     * Compresses the given image using Pica, maintaining its aspect ratio.
     *
     * @param {HTMLImageElement} img - The image element to be compressed.
     * @param {number} maxWidth - The maximum width for the compressed image.
     * @param {number} maxHeight - The maximum height for the compressed image.
     * @returns {Promise<string>} A promise resolving to the compressed base64 image.
     */
    const compressImage = async (img, maxWidth, maxHeight) => {
        const pica = new Pica();
        const canvas = document.createElement('canvas');
        const aspectRatio = img.width / img.height;

        // Maintain aspect ratio based on dimensions
        if (img.width > img.height) {
            canvas.width = maxWidth;
            canvas.height = Math.round(maxWidth / aspectRatio);
        } else {
            canvas.height = maxHeight;
            canvas.width = Math.round(maxHeight * aspectRatio);
        }

        return new Promise((resolve, reject) => {
            pica.resize(img, canvas, {
                unsharpAmount: 80,
                unsharpRadius: 0.6,
                unsharpLevel: 0.25,
            })
                .then((result) => pica.toBlob(result, 'image/jpeg', 0.9))
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result); // Resolve with base64 image
                    };
                    reader.readAsDataURL(blob);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    // Return the functions for use in components
    return { addTimestampAndGeolocation, addTimestamp, addGeolocation, compressImage };
};