import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Device } from '@capacitor/device';

import CommonButton from '../../../buttons/common-button';
import InfoBox from '../../../blocks/messages/info-box';
import ToggleButton from '../../../buttons/toggle-button';

import { useCameraPhoto } from '../../../../modules/cameraModule'

import MediasService from '../../../../services/MediasService';
import CustomModal from '../../../blocks/custom-modal';

/**
 * MediaUploadForm Component
 * 
 * This component renders a form for uploading media files.
 *
 * @param {string} base64media - The media file to upload
 * @param {string} type - The type of media file (image or video)
 * @param {object} folder - The folder to upload the media file to
 * @param {boolean} geolocation - The geolocation data for the media file
 * @param {boolean} timestamp - The timestamp data for the media file
 * @param {function} onGeolocationChange - Callback function to handle geolocation changes
 * @param {function} onTimestampChange - Callback function to handle timestamp changes
 * 
 * @returns {JSX.Element}
 */
const MediaUploadForm = ({
    base64media,
    type,
    folder,
    geolocation,
    timestamp,
    onGeolocationChange,
    onTimestampChange,
}) => {
    // Import the useCameraPhoto hook from the camera module
    const { savePhoto } = useCameraPhoto();

    // State variables for media categories
    const [mediaCategories, setMediaCategories] = useState([]);

    // State variables for geolocation, timestamp and selected category
    const [selectedCategory, setSelectedCategory] = useState('');

    // State variables for loading and error handling
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    // State variable for the preview modal
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    /**
     * fetchMediaCategories
     * 
     * This asynchronous function fetches media categories from the API
     * and updates the state with the results.
     * 
     * @returns {Promise<void>}
     */
    const fetchMediaCategories = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch media categories from the API
            const categories = await MediasService.getMediaCategories();

            // Check if categories are found
            if (!categories || categories.length === 0) {
                setError('Aucune catégorie trouvée.'); // Set error message if no categories are found
                return;
            }

            // Update the state with the fetched categories
            setMediaCategories(categories);
        } catch (error) {
            setError(error?.message || 'Une erreur inattendue est survenue'); 
        } finally {
            setLoading(false);
        }
    };

    /**
     * handlePreview
     * 
     * This function handles the "Prévisualiser" button click.
     * It triggers the onImageUpload callback to send the captured media
     * and all form data back to the parent component.
     */
    const handlePreview = () => {

        // Check if an media has been captured
        if (!base64media) {
            setError('Veuillez capturer un média avant de continuer.');
            return;
        }

        // Check if a category has been selected
        if (!selectedCategory) {
            setError('Veuillez sélectionner une catégorie.');
            return;
        }

        // Find the folder by ID
        if (!folder) {
            setError('Le dossier est introuvable.');
            return;
        }

        setIsPreviewOpen(true); // Ouvrir la modale
    };

    /**
     * handleSave
     * 
     * This function handles the "Importer" button click.
     * It triggers the onImageUpload callback to send the captured media 
     * and all form data back to the parent component.
     */
    const handleSave = async () => {

        // Check if an media has been captured
        if (!base64media) {
            setError('Veuillez capturer un media avant de continuer.');
            return;
        }

        if (!selectedCategory) {
            setError('Veuillez sélectionner une catégorie.');
            return;
        }

        // Find the folder by ID
        if (!folder) {
            setError('Le dossier est introuvable.');
            return;
        }

        // Generate a payload object to send to the API
        const payload = {
            file: base64media,
            id_folder: folder.id,
            id_media_category: parseInt(selectedCategory, 10),
        }; // Create a payload object to send to the API

        // Process the media file based on the device platform
        const info = await Device.getInfo();

        // Check if the device information is available
        if (!info) {
            setError('Impossible de récupérer les informations du périphérique.');
            return;
        }

        try {
            // Save the photo to the device if it is an iOS or Android device
            if (['ios', 'android'].includes(info.platform) && type === 'image') {
                await savePhoto(base64media, folder.folder_number);
            }
            
            // Upload the media file to the API
            await MediasService.uploadMedia(payload);
        } catch (error) {
            setError(error?.message || 'Une erreur inattendue est survenue');
            return;
        }

        // Display a success message if the operation is successful
        setSuccess('Le média a été importé avec succès.');
        setIsPreviewOpen(false); // Close the modal
    };

    // Fetch media categories on component mount
    useEffect(() => {
        fetchMediaCategories();
    }, []);

    // Display a loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    return (
        <React.Fragment>
            <div className='media-upload--form w-50'>

                { /* Display the error message if an error occurs */ }
                { error && <InfoBox type="error" text={error} disabledCloseBtn={true} /> }

                { /* Display the success message if the operation is successful */ }
                { success && <InfoBox text={success} disabledCloseBtn={true} /> }

                {/* Category group selection */}
                <div className="media-upload--select">
                    <label>Catégorie de la photo</label>
                    <select
                        value={selectedCategory || ''}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value="">Sélectionnez un groupe</option>
                        {mediaCategories.map(group => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Category name and group selection */}
                <div className="media-upload--toggle">
                    <label>Géolocalisation</label>
                    <ToggleButton
                        isActive={geolocation}
                        onToggle={onGeolocationChange}
                    />
                </div>

                {/* Category name and group selection */}
                <div className="media-upload--toggle">
                    <label>Horodatage</label>
                    <ToggleButton
                        isActive={timestamp}
                        onToggle={onTimestampChange}
                    />
                </div>

                {/* Display the form with the fields and submit buttons */}
                <CommonButton
                    label="Importer"
                    onClick={handlePreview}
                    className="btn-save-media"
                />   
            </div>

            { /* Display the media preview modal */ }
            {isPreviewOpen && (
                <CustomModal
                    open={isPreviewOpen}
                    onClose={() => setIsPreviewOpen(false)}
                    title="Prévisualisation avant import"
                    handleSave={handleSave}
                    handleSaveLabel={'Confirmer et Importer'}
                >
                    { /* Display the information box */ }
                    <InfoBox
                        type="warning"
                        text={'Assurez-vous que les informations ci-dessous sont correctes avant d\'importer le média. Vous ne pourrez pas modifier ces informations après l\'importation.'}
                    />

                    { /* Display the media preview */ }
                    <div className="media-import-preview">
                        {type === 'image' ? (
                            <img
                                src={`${base64media}`}
                                alt="Prévisualisation"
                            />
                        ) : type === 'video' ? (
                            <video controls>
                                <source src={`${base64media}`} type="video/mp4" />
                                Votre navigateur ne supporte pas la lecture des vidéos.
                            </video>
                        ) : (
                            <p>Média non pris en charge.</p>
                        )}

                        <p><strong>Catégorie :</strong> {mediaCategories.find(cat => cat.id === parseInt(selectedCategory, 10))?.name}</p>
                        <p><strong>Géolocalisation :</strong> {geolocation ? 'Activée' : 'Désactivée'}</p>
                        <p><strong>Horodatage :</strong> {timestamp ? 'Activé' : 'Désactivé'}</p>
                    </div>
                </CustomModal>
            )}
        </React.Fragment>
    );
};

// Prop types for the MediaUploadForm component
MediaUploadForm.propTypes = {
    base64media: PropTypes.string,
    type: PropTypes.string,
    folder: PropTypes.object.isRequired,
    geolocation: PropTypes.bool,
    timestamp: PropTypes.bool,
    onGeolocationChange: PropTypes.func.isRequired,
    onTimestampChange: PropTypes.func.isRequired
};

export default MediaUploadForm;
