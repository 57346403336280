import React from 'react'; // Import React

import Container from '../../components/blocks/container'; // Custom container component for layout
import ItemCard from '../../components/blocks/item-card'; // Custom item card component for displaying settings

import { useNavigate } from 'react-router-dom'; // Hook for programmatic navigation

import {
    Build,
    Tune,
    Accessibility
} from '@mui/icons-material'; // Icons for various settings

/**
 * Settings component: Displays the settings options for the application.
 * Each setting links to a corresponding settings page.
 */
const Settings = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    // List of settings options, each with an icon, name, description, and click handler
    const settingsList = [
        {
            icon: <Tune />, // Icon for application configuration
            name: 'Configuration', // Name of the setting
            description: 'Modifier les paramètres de l\'application.', // Description of the setting
            onClick: () => navigate('/settings/app-config'), // Navigate to app configuration page on click
        },
        {
            icon: <Accessibility />, // Icon for accessibility settings
            name: 'Paramètres d\'accessibilité', // Name of the setting
            description: 'Modifier les paramètres d\'accessibilité de l\'application, y compris le changement de thème.', // Description of the setting
            onClick: () => navigate('/settings/app-accessibility'), // Navigate to accessibility settings page on click
        },
        {
            icon: <Build />, // Icon for app information
            name: 'À propos de l\'application', // Name of the setting
            description: 'Informations générales sur l\'application. Identifiant unique, version, ...', // Description of the setting
            onClick: () => navigate('/settings/app-details'), // Navigate to app details page on click
        },
    ];

    // Render the settings options as a list of ItemCard components
    return (
        <Container>
            <ul className='settings-block'>
                {settingsList.map((setting, index) => (
                    <ItemCard
                        key={index} // Unique key for each setting
                        icon={setting.icon} // Icon for the setting
                        name={setting.name} // Name of the setting 
                        description={setting.description} // Description of the setting
                        onClick={setting.onClick} // Click handler for navigating
                        aria-labelledby={`setting-${index}-label`} // Associate with a label for accessibility
                        aria-describedby={`setting-${index}-description`} // Associate with a description for accessibility
                    />
                ))}
            </ul>
        </Container>
    );
};

export default Settings; // Export the Settings component
