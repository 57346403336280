import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom'; // Import required hooks and components

import { Dashboard, Menu, Home, Camera, Group, CheckCircleOutline, AlternateEmailOutlined } from '@mui/icons-material'; // Import MUI icons

/**
 * AdminSidebar Component
 * This component renders a sidebar for the admin panel using native HTML elements
 * and Material-UI icons.
 */
const AdminSidebar = () => {
    // State to track the currently selected menu item
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isMenuActive, setIsMenuActive] = useState(false); // State for toggling menu visibility

    // Hook to access the current browser location (URL path)
    const location = useLocation();

    // Array of menu items with name, link, and icon properties
    const menuItems = [
        { name: 'Retour à l\'accueil', link: '/', icon: <Home /> },
        { name: 'Tableau de bord', link: '/admin', icon: <Dashboard /> },
        { name: 'Gestion des médias', link: '/admin/medias', icon: <Camera /> },
        { name: 'Gestion des utilisateurs', link: '/admin/users', icon: <Group /> },
        { name: 'Gestion des actions d\'intervention', link: '/admin/appointment-actions', icon: <CheckCircleOutline /> },
        { name: 'Gestion des destinataires de mail', link: '/admin/mail-recipients', icon: <AlternateEmailOutlined /> }
    ];

    useEffect(() => {
        // Helper function to normalize the path by removing trailing slashes
        const normalizePath = (path) => {
            return path.endsWith('/') && path !== '/' ? path.slice(0, -1) : path;
        };
    
        // Determine the index of the currently active menu item based on the URL path
        const currentIndex = menuItems.findIndex(item => {
            const normalizedNavigatePath = normalizePath(item.link);
            const normalizedLocationPath = normalizePath(location.pathname);
    
            return normalizedNavigatePath === normalizedLocationPath;
        });
    
        // Update the selected index based on the current route
        setSelectedIndex(currentIndex);
    }, [location.pathname, menuItems]);
    
    /**
     * Event handler for when a menu item is clicked.
     * It closes the menu and updates the selected index.
     * 
     * @param {string} link - The link of the clicked menu item.
     */
    const handleItemClick = (link) => {
        setSelectedIndex(menuItems.findIndex(item => item.link === link)); // Update the selected index
        setIsMenuActive(false); // Close the menu after clicking
    };

    /**
     * Toggles the menu visibility when the menu button is clicked.
     * It updates the state to show or hide the menu.
     * 
     * @returns {void}
     */
    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
    };

    // Render the sidebar with menu items
    return (
        <React.Fragment>

            {/* Button to toggle the menu visibility */}
            <button className="admin-sidebar-btn-menu" onClick={toggleMenu}>
                <Menu /> {/* MUI Menu Icon */}
                <span>Menu</span> {/* Display the folder number */}
            </button>

            {/* Sidebar with menu items */}
            <div className={`admin-sidebar ${isMenuActive ? 'active' : ''}`}>
                {/* Render the menu items */}
                <ul className="admin-menu">
                    {menuItems.map((item, index) => (
                        <li
                            key={index}
                            className="admin-menu-item"
                        >
                            {/* Use Link from react-router-dom for navigation */}
                            <Link
                                to={item.link}
                                className={`admin-menu-link ${selectedIndex === index ? 'active' : ''}`}
                                onClick={() => handleItemClick(item.link)} // Call handleItemClick on link click
                            >
                                {item.icon}
                                <span className="menu-text">{item.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default AdminSidebar;
