
import React, { useEffect, useState } from 'react';

import { Modal } from '@mui/material';
import { PlayCircleOutline } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import { Share as CapacitorShare } from '@capacitor/share';
import { Filesystem, Directory } from '@capacitor/filesystem';

import { useGalleryPhoto } from '../hooks/useGalleryPhoto';

import '../assets/style.css';

/**
 * MediaGallery component: 
 * Displays a gallery of images and videos with a modal to preview them.
 */
const MediaGallery = () => {

    // Custom hook to fetch images and videos
    const { getImages } = useGalleryPhoto();
    const [mediaFiles, setMediaFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);

    // State for loading state
    const [loading, setLoading] = useState(false); // State for loading state
    const [error , setError] = useState(null); // State for error handling

    /**
     * Fetches media files from the API.
     * 
     * @returns {Promise<void>} A promise that resolves when the data is fetched.
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchMedia = async () => {
        try {
            // Set loading state to true
            setLoading(true); 

            // Fetch images and videos using the custom hook
            const response = await getImages();

            // Throw an error if no media files are found
            if (!response || !response.images) {
                throw new Error("Aucun fichier multimédia trouvé.");
            }

            // Update state with fetched media files
            setMediaFiles(response.images); 
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false); // Set loading to false when done
        }
    };

    /**
     * Opens the modal and sets the selected media index.
     * @param {number} index - The index of the media to preview.
     */
    const handleOpen = (index) => {
        setSelectedMediaIndex(index);
        setOpen(true);
    };

    /**
     * Closes the modal and resets the selected media index.
     */
    const handleClose = () => {
        setOpen(false);
        setSelectedMediaIndex(null);
    };

    /**
     * Shares the selected media file.
     * Uses the Capacitor Filesystem to save the file in the cache directory
     * and then uses the Capacitor Share API to share the file.
     * 
     * @returns {Promise<void>} A promise that resolves when the media is shared.
     * @throws {Error} Throws an error if sharing fails
     */
    const handleShare = async () => {
        try {
            if (selectedMediaIndex !== null) {

                // Get the selected media file
                const mediaUrl = mediaFiles[selectedMediaIndex];

                // Fetch the media file as a blob
                const response = await fetch(mediaUrl);
                const blob = await response.blob();

                // Save the file to the cache directory
                const base64Data = await blobToBase64(blob);
                const fileName = `shared_media_${Date.now()}.jpg`;
                const savedFile = await Filesystem.writeFile({
                    path: fileName,
                    data: base64Data,
                    directory: Directory.Cache,
                });

                // Share the file using Capacitor Share
                await CapacitorShare.share({
                    title: 'Share Media',
                    text: 'Check out this amazing media!',
                    url: savedFile.uri,
                    dialogTitle: 'Share via'
                });
            }
        } catch (error) {
            console.error("Error sharing media", error); // Log errors
        }
    };

    /**
     * Converts a Blob to a Base64 string.
     * @param {Blob} blob - The blob to convert.
     * @returns {Promise<string>} A promise that resolves to the Base64 string.
     */
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    /**
     * Determines if the given media is a video.
     * @param {string} media - The media URL.
     * @returns {boolean} - True if the media is a video, false otherwise.
     */
    const isVideo = (media) => {
        return media && (media.endsWith('.mp4') || media.endsWith('.mov') || media.endsWith('.webm'));
    };

    // Fetch media files on component mount
    useEffect(() => {
        fetchMedia();
    }, []);

    // Return the loading state wrapped inside the container class
    if (loading) {
        return (
            <div className="media-module-container">
                <p>Chargement des fichiers multimédias...</p>
            </div>
        );
    }

    // Return the error state wrapped inside the container class
    if (error) {
        return (
            <div className="media-module-container">
                <div className='error-message'>
                    <p className='error-label'>Une erreur s'est produite lors du chargement des fichiers multimédias.</p>
                    <p className='error-details'>{error}</p>
                </div>
            </div>
        );
    }

    // Return the gallery or a message if no media files are found
    if (!mediaFiles || mediaFiles.length === 0) {
        return (
            <div className="media-module-container">
                <p>Aucun fichier multimédia trouvé.</p>
            </div>
        );
    }

    return (
        <div className="media-module-container"> {/* Main container for the media gallery */}
            <div className="media-files">
                {mediaFiles.map((media, index) => (
                    <div key={index} className="media-wrapper" onClick={() => handleOpen(index)}>
                        {isVideo(media) ? (
                            <React.Fragment>
                                <PlayCircleOutline className="video-icon" />
                                <video
                                    src={media}
                                    alt={`Gallery ${index + 1}`}
                                    className="gallery-media"
                                    loading="lazy"
                                    muted
                                    loop
                                    controls
                                />
                            </React.Fragment>
                        ) : (
                            <img
                                src={media}
                                alt={`Gallery ${index + 1}`}
                                className="gallery-media"
                                loading="lazy"
                            />
                        )}
                    </div>
                ))}
            </div>

            <Modal 
                open={open} 
                onClose={handleClose} 
                className="gallery-preview-modal"
                aria-labelledby="modal-title" 
                aria-describedby="modal-description"
            >
                <div className="modal-content">
                    {/* Preview media */}
                    <div className='modal-content--left-column'>
                        {selectedMediaIndex !== null && (
                            isVideo(mediaFiles[selectedMediaIndex]) ? (
                                <video
                                    src={mediaFiles[selectedMediaIndex]}
                                    alt={`Full view of gallery ${selectedMediaIndex + 1}`}
                                    className="modal-preview-media"
                                    controls
                                    autoPlay
                                />
                            ) : (
                                <img
                                    src={mediaFiles[selectedMediaIndex]}
                                    alt={`Full view of gallery ${selectedMediaIndex + 1}`}
                                    className="modal-preview-media"
                                />
                            )
                        )}
                    </div>

                    {/* Close button */}
                    <div className='modal-content--right-column'>
                        <button onClick={handleClose} aria-label="Fermer la prévisualisation" className="close-button">
                            <CloseFullscreenIcon /> {/* Close icon */}
                            Fermer
                        </button>
                        <button onClick={handleShare} aria-label="Partager ce média" className="share-button">
                            <ShareIcon /> {/* Share icon */}
                            Partager
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MediaGallery;