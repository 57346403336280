import React, { useState } from 'react';

import Logo from '../assets/images/logo.jpg';

import { Clipboard } from '@capacitor/clipboard';
import { Device } from '@capacitor/device';

import { Button } from '@mui/material';

import UsersService from '../services/UsersService';

import { useDevice } from '../modules/deviceModule';

/**
 * LoginPage Component
 * 
 * This component renders the update page.
 * This page is displayed when a new version of the app is available.
 * The user can click on the button to update the app.
 * 
 * @returns {JSX.Element} The rendered LoginPage component.
 */
const LoginPage = () => {
    // State variables
    const [authKey, setAuthKey] = useState(''); // State variable to store the authentication key
    const [isValidKey, setIsValidKey] = useState(true); // State for validating the key

    const {identifier} = useDevice();
    const [showLinkMessage, setShowLinkMessage] = useState(null); // State to toggle device identifier display

    // State for error message
    const [error, setError] = useState(null); // State for error message

    /**
     * Handles the change event for the authentication key input field.
     * This function updates the authKey state and validates the key format.
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
     * @returns {void}
     */
    const handleAuthKeyChange = (event) => {
        const value = event?.target?.value;
        setAuthKey(value);

        // Validate the length and format of the authentication key
        const uuidRegex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;
        
        // Check if the key matches the UUID format
        if (uuidRegex.test(value)) {
            setIsValidKey(true);
            setError(null);
        } else {
            setIsValidKey(false);
            console.error('Invalid key format');
            setError('Veuillez entrer une clé valide au format UUID (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)');
        }
    };

    /**
     * Handles the login button click event.
     * This function validates the authentication key and connects to the server.
     * 
     * @returns {void}
     * @throws {Error} Throws an error if the connection fails.
     */
    const handleLoginClick = async () => {
        // Connect to the server with the authentication key
        if (isValidKey) {
            try {
                // Connect to the server using the authentication key and device identifier
                await UsersService.connect(authKey, identifier);
            } catch (error) {
                setError(error?.message || "Une erreur inattendue est survenue");
            }
        } else {
            console.error('Invalid key format');
            setError('Veuillez entrer une clé valide au format UUID (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)');
        }
    };

    /**
     * Handles the resend key button click event.
     * This function displays a message to contact an administrator to resend the key.
     * 
     * @returns {void}
     */
    const handleResendKey = async () => {
        setShowLinkMessage(
            <React.Fragment>
                <p>Merci de contacter un administrateur pour qu'il vous renvoie la clé d’authentification.</p>
            </React.Fragment>
        );
    };

    /**
     * Handles the show device identifier button click event.
     * This function displays the device identifier and a button to copy it.
     * 
     * @returns {void}
     */
    const handleShowDeviceIdentifier = () => {            
        setShowLinkMessage(
            <React.Fragment>
                <p>Identifiant de l’appareil: <strong>{identifier}</strong></p>
                <button
                    onClick={handleCopyDeviceIdentifier}
                    className='copy-button'
                >
                    Copier
                </button>
            </React.Fragment>
        );
    }

    /**
     * Handles the copy device identifier button click event.
     * This function copies the device identifier to the clipboard.
     * 
     * @returns {void}
     * @throws {Error} Throws an error if copying fails.
     */
    const handleCopyDeviceIdentifier = async () => {
        try {
            // Get device information
            const info = await Device.getInfo();

            // Check if device information is available
            if (!info) {
                console.error('Device information not available');
                setError('Informations sur l\'appareil non disponibles');
                return;
            }

            // Copy the device identifier to the clipboard
            if (info.platform !== 'web') {
                await Clipboard.write({ string: identifier });
            } else {
                // For web platforms, use the Clipboard API
                if (navigator?.clipboard) {
                    navigator?.clipboard?.writeText(identifier)
                } else {
                    setError('Clipboard API not supported');
                    console.error('Clipboard API not supported');
                }
            }            
        } catch (error) {
            console.error(error?.message);
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // Render the login page
    return (
        <div className="login-page">
            <div className="login-content">
                {/* Displaying the logo */}
                <img src={Logo} alt="Logo" className="logo" />

                {/* Input field for authentication key */}
                <input
                    type="text"
                    className={`auth-key-input ${!isValidKey ? 'error' : ''}`}
                    placeholder="Clé d'authentification: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                    value={authKey}
                    onChange={handleAuthKeyChange}
                />

                { /* Display error message if there is an error */ }
                {error && <p className='error-message'>{error}</p>}
                
                {/* Button to trigger the login */}
                <Button
                    variant="contained"
                    className="login-button"
                    onClick={handleLoginClick}  // Trigger login when clicked
                    disabled={!isValidKey} // Disable button if key length is less than 36
                >
                    Connexion sécurisée
                </Button>

                {/* Links for additional actions */}
                <div className="additional-actions">
                    {/* Link to resend session key */}
                    <button
                        className="link-button"
                        onClick={handleResendKey}
                    >
                        Clé d'authentification perdue
                    </button>

                    {/* Link to toggle device identifier display */}
                    <button
                        className="link-button"
                        onClick={handleShowDeviceIdentifier}
                    >
                        Afficher l’identifiant de l’appareil
                    </button>

                    {/* Display the device identifier if toggled */}
                    {showLinkMessage && (
                        <p className="link-information">
                            {showLinkMessage}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;