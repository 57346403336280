import React from 'react';

import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';

/**
 * MediaUploadPreview Component
 * 
 * This component displays a preview of the uploaded media file.
 * 
 * @param {object} base64file - The base64 representation of the uploaded file
 * @param {boolean} loading - Whether the media is loading or not
 * @param {string} type - The type of media file (image or video)
 * 
 * @returns {JSX.Element}
 */
const MediaUploadPreview = ({
    base64file,
    loading,
    type
}) => {
    // Return the JSX for the MediaUploadPreview component
    return (
        <div className='preview-wrapper'>           
            {base64file && (
                <React.Fragment>
                     { /* Display the media preview if a file is uploaded */ }
                    <h3>Média capturé</h3>

                    { type === 'video' ? (
                        <video
                            controls
                            className="preview-video"
                            aria-label="Preview de la vidéo capturée"
                        >
                            <source src={base64file} type="video/mp4" />
                            Votre navigateur ne supporte pas la balise vidéo.
                        </video>
                    ) : (
                        <img 
                            src={base64file} 
                            alt="Captured" 
                            className="preview-image" 
                            aria-label="Preview de l'image capturée"
                        />
                    )}
                </React.Fragment>
            )}

            { /* Display a loading spinner if mediaLoading is true */ }
            {loading && (
                <div className="loading-wrapper">
                    <CircularProgress />
                    <span>Traitement du média en cours</span>
                </div>
            )}
        </div>
    );
};

// Prop types for the MediaUploadPreview component
MediaUploadPreview.propTypes = {
    base64file: PropTypes.string,
    loading: PropTypes.bool,
    type: PropTypes.string
};

export default MediaUploadPreview;
