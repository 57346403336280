import ApiService from './ApiService';

/**
 * Service for handling folder-related API requests.
 */
class PaymentsService extends ApiService {

    async addFolderPayment(payload) {
        // Validate payload
        if (!payload || Object.keys(payload).length === 0) {
            console.error('Payload is required');
            throw new Error('Les données de paiement sont requises');
        }

        try {
            const response = await this.request('POST', `payment/create`, payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
}

const paymentsService = new PaymentsService();
export default paymentsService;