import React, { useState } from 'react';
import { useConnection } from '../../context/connection-context';

import CloseIcon from '@mui/icons-material/Close';
import { WifiOffOutlined } from '@mui/icons-material';

/**
 * ConnectionPopIn Component
 * This component displays a pop-in when the user is offline.
 * The pop-in can be closed by clicking on the close button.
 * 
 * @returns {JSX.Element} - Rendered component
 */
const ConnectionPopIn = () => {

    // Get the connection status
    const { isOnline } = useConnection();
    const [isVisible, setIsVisible] = useState(true);

    // Return null if the user is online
    if (isOnline) return null;

    // Render the pop-in
    return (
        isVisible ? (
            <div className="connection-popin">
                <div className="popin-content">
                    <div className="popin-header">
                        <WifiOffOutlined />
                        <p><strong>Attention !</strong> Vous êtes hors connexion.</p>
                    </div>
                    <p>Certaines fonctionnalités peuvent ne pas être disponibles.</p>
                </div>

                <button
                    className="close-popin"
                    onClick={() => setIsVisible(false)}
                >
                    <CloseIcon />
                </button>
            </div>
        ) : (
            <button
                className="connection-popin--icn"
                onClick={() => setIsVisible(true)}
            >
                <WifiOffOutlined />
            </button>
        )
    );
};

export default ConnectionPopIn;
