// videoModule/hooks/useVideoRecorder.jsx

/**
 * Custom hook for managing video recording functionalities such as starting,
 * stopping the recording, and saving the video file.
 */
export const useVideoRecorder = () => {
    /**
     * Function to start recording video.
     * 
     * @returns {Promise<void>} - Resolves when recording starts.
     * @throws {Error} - Throws an error if recording fails.
     */
    const startRecording = async () => {
        try {

            // Check if the app is running natively
            if (!navigator.device || !navigator.device.capture) {
                throw new Error('La capture vidéo n\'est pas disponible sur cet appareil.');
            }
    
            // Return a Promise that resolves when the recording is finished
            return new Promise((resolve, reject) => {
                // Start recording video using the device's camera
                navigator.device.capture.captureVideo(
                    // Success: Register the video file
                    async (mediaFiles) => {
                        try {
                            if (mediaFiles.length === 0) {
                                reject(new Error('Erreur : Aucune vidéo enregistrée'));
                                return;
                            }

                            for (const file of mediaFiles) {
                                try {
                                    // Convert the video to base64 format
                                    const base64Video = await convertToBase64(file.fullPath);
                                    resolve(base64Video);
                                } catch (err) {
                                    reject(new Error("Erreur lors de la conversion de la vidéo en base64."));
                                }
                            }
                        } catch (err) {
                            reject(new Error("Erreur lors de la capture de la vidéo."));
                        }
                    },

                    // Error: Log the error message
                    (error) => {
                        reject(new Error("Erreur lors de la capture de la vidéo :", error));
                    },

                    // Options: Limit to 1 video, 30 seconds duration, and low quality
                    { limit: 1, duration: 30, quality: 0 }
                );
            });
        } catch (error) {
            throw new Error("Erreur lors du démarrage de l'enregistrement vidéo.");
        }
    };
    
    /**
     * Function to convert a video file to base64 format.
     * @param {string} filePath - The file path of the video to convert.
     * @returns {Promise<string>} - The base64 string of the video.
     * @throws {Error} - Throws an error if the conversion fails.
     */
    const convertToBase64 = (filePath) => {
        return new Promise((resolve, reject) => {

            // Resolve the file entry
            window.resolveLocalFileSystemURL(
                filePath,
                (fileEntry) => {
                    fileEntry.file(
                        (file) => {
                            const reader = new FileReader();

                            // Resolve with the base64 data
                            reader.onloadend = () => {
                                resolve(reader.result); 
                            };

                            // Reject on error
                            reader.onerror = (error) => {
                                reject(error);
                            };

                            // Read the file as Data URL
                            reader.readAsDataURL(file);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                },
                (error) => {
                    reject(error);
                }
            );
        });
    };   
    
    // Return the functions for use in components
    return {
        startRecording
    };
};