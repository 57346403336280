import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
    PhotoCameraOutlined,
    SlowMotionVideoOutlined,
    CollectionsOutlined
} from '@mui/icons-material';

import SquareButton from '../../buttons/square-button';
import InfoBox from '../../blocks/messages/info-box';
import MediaUploadForm from './components/folder--media--upload-form';
import MediaUploadPreview from './components/folder--media--upload-preview';

import { useCameraPhoto, useImageProcessing, useVideoRecorder } from '../../../modules/cameraModule'

import { useGallery } from '../../../context/gallery-context'; // Context for gallery preferences

const UploadSection = ({ folder }) => {

    // Import the useCameraPhoto hook from the camera module
    const { takePhoto, selectPhoto } = useCameraPhoto();
    const { startRecording } = useVideoRecorder();

    // Import the useGallery hook from the gallery module
    const { addTimestampAndGeolocation, addTimestamp, addGeolocation } = useImageProcessing();
    const { timestamp, geolocation } = useGallery();

    // State variables for geolocation and timestamp options 
    const [geolocationOption, setGeolocationOption] = useState(geolocation);
    const [timestampOption, setTimestampOption] = useState(timestamp);

    // State variables for captured and processed images
    const [processedImage, setProcessedImage] = useState(null);
    const [processedVideo, setProcessedVideo] = useState(null);
    const [mediaLoading, setMediaLoading] = useState(false);

    // State variables for error handling
    const [error, setError] = useState(null);

    /**
     * resetMediaData
     */
    const resetMediaData = () => {
        setProcessedImage(null);
        setProcessedVideo(null);
    }

    /**
     * handleTakePhoto
     * 
     * This asynchronous function is called when the photo button is clicked.
     * It captures an image and processes it based on selected options.
     */
    const handleTakePhoto = async () => {
        try {
            // Reset the media data before capturing a new photo
            resetMediaData();

            // Set loading to true when starting to take a photo
            setMediaLoading(true); 

            // Capture the photo using the browser's media devices
            const image = await takePhoto();

            // Validate the captured image before updating the state
            if (!image) {
                setError('Aucune image capturée.');
                return;
            }

            // Validate the captured image before updating the state
            if (typeof image !== 'string' || !image.startsWith('data:image/')) {
                setError('Image invalide.');
                return;
            }
                
            // Process the captured image
            const processedImage = await processImage(image);

            // Validate the processed image before updating the state
            if (!processedImage) {
                setError('Erreur lors du traitement de l\'image.');
                return;
            }

            // Update the state with the processed image
            setProcessedImage(processedImage);

            // Reset the error message
            setError(null);
        } catch (error) {
            setError(error?.message || 'Une erreur inattendue est survenue');
        } finally {
            setMediaLoading(false);
        }
    };

    /**
     * handleSelectPhoto
     * 
     * This asynchronous function is called when the select photo button is clicked.
     * It allows the user to choose an image from their device and processes it based on selected options.
     */
    const handleSelectPhoto = async () => {
        try {
            // Reset the media data before capturing a new photo
            resetMediaData();

            // Set loading to true when starting to select a photo
            setMediaLoading(true);

            // Select photo with preferences
            const image = await selectPhoto();

            // Validate the captured image before updating the state
            if (!image) {
                setError('Aucune image capturée.');
                return;
            }

            // Validate the captured image before updating the state
            if (typeof image !== 'string' || !image.startsWith('data:image/')) {
                setError('Image invalide.');
                return;
            }

            // Process the captured image
            const processedImage = await processImage(image);

            // Validate the processed image before updating the state
            if (!processedImage) {
                setError('Erreur lors du traitement de l\'image.');
                return;
            }
             
            // Update the state with the processed image
            setProcessedImage(processedImage);

            // Reset the error message
            setError(null);
        } catch (error) {
            setError(error?.message || 'Une erreur inattendue est survenue');
        } finally {
            setMediaLoading(false);
        }
    };

    /**
     * handleRecordVideo
     * 
     * This asynchronous function is called when the record video button is clicked.
     * It allows the user to record a video using the device's camera and processes it.
     */
    const handleRecordVideo = async () => {
        try {
            // Reset the media data before capturing a new photo
            resetMediaData();

            // Set loading to true when starting to record a video
            setMediaLoading(true);

            // Start recording
            const base64video = await startRecording();

            // Validate the recorded video before updating the state
            if (!base64video) {
                setError('Aucune vidéo capturée.');
                return;
            }

            // Update the state with the processed video
            setProcessedVideo(base64video);

            // Reset the error message
            setError(null);
        } catch (error) {
            setError(error?.message || 'Une erreur inattendue est survenue');
        } finally {
            setMediaLoading(false);
        }
    };

    /**
     * processImage
     * 
     * This function processes the captured image based on the selected
     * options for timestamp and geolocation.
     * 
     * @param {string} image - The captured image to process.
     * @returns {Promise<string>} - The processed image with added timestamp and/or geolocation.
     */
    const processImage = async (image) => {

        // Check if the image is missing
        if (!image) return null;

        try {
            // Process the image based on user preferences
            if (timestampOption && geolocationOption) {

                // Add timestamp and geolocation
                return await addTimestampAndGeolocation(image);
            } else {
                if (timestampOption) {
                    // Add timestamp only
                    return await addTimestamp(image);
                }
                if (geolocationOption) {
                    // Add geolocation only
                    return await addGeolocation(image); 
                }
            }
        } catch (error) {
            setError(error?.message || 'Erreur lors du traitement de l\'image.');
        }
    };  

    // Render the component
    return (
        <div className='media-upload--section'>
            <h2>Ajouter un média au dossier</h2>

            { /* Display the error message if an error occurs */ }
            { error && <InfoBox type="error" text={error} disabledCloseBtn={true} /> }

            {/* Display the error message if an error occurs */}
            <div className='media-tools--manager w-50'>
                <div className="media-tools-bar">
                    <SquareButton
                        icon={<PhotoCameraOutlined />} // Pass the icon as JSX
                        label="Prendre une photo"
                        onClick={handleTakePhoto} // Call function on button click
                        mobileStickerEnabled={true} // Add mobile sticker functionality
                        className="btn-take-photo"
                    />
                    <SquareButton
                        icon={<SlowMotionVideoOutlined />} // Pass the icon as JSX
                        label="Prendre une vidéo"
                        onClick={handleRecordVideo} // Call function on button click
                        mobileStickerEnabled={true} // Add mobile sticker functionality
                        className="btn-take-video"
                    />
                    <SquareButton
                        icon={<CollectionsOutlined />} // Pass the icon as JSX
                        label="Ouvrir la galerie du téléphone"
                        onClick={handleSelectPhoto} // Call function on button click
                        mobileStickerEnabled={true} // Add mobile sticker functionality
                        className="btn-open-gallery"
                    />
                </div>

                {/* Display the preview of the captured image */}
                <MediaUploadPreview
                    base64file={processedImage || processedVideo || null}
                    loading={mediaLoading}
                    type={processedImage ? 'image' : (processedVideo ? 'video' : null)}
                />
            </div>

            {/* Display the form with the fields and submit buttons */}
            <MediaUploadForm
                base64media={processedImage || processedVideo || null}
                type={processedImage ? 'image' : (processedVideo ? 'video' : null)}
                folder={folder}
                geolocation={geolocationOption}
                timestamp={timestampOption}
                onGeolocationChange={() => setGeolocationOption(!geolocationOption)}
                onTimestampChange={() => setTimestampOption(!timestampOption)}
            />
        </div>
    );
};

// Prop types for the UploadSection component
UploadSection.propTypes = {
    folder: PropTypes.object.isRequired,
};

export default UploadSection;
