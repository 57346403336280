import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';
import MailsService from '../../../../services/MailsService';

/**
 * CreateMailRecipientModal Component
 *
 * This component displays a modal for creating a new mail recipient.
 * It takes `open`, `onClose`, and `onCreate` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose (function): Function to close the modal.
 * - onCreate (function): Function to handle the creation logic.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const CreateMailRecipientModal = ({ open, onClose, onCreate }) => {
    // State to handle loading state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [mail, setMail] = useState('');

    /**
     * Handles the creation of a new mail recipient
     *
     * @returns {void}
     */
    const handleConfirmCreate = async () => {
        // Check if the mail address is empty
        if (!mail.trim()) {
            console.error('Mail address is required');
            setError('L\'adresse e-mail est requise.');
            return;
        }

        // Regular expression to validate email addresses
        const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        // Check if the email address is valid
        if (!mailRegex.test(mail)) {
            console.error('Invalid email address');
            setError('Adresse e-mail invalide.');
            return;
        }

        try {
            // Set loading state to true
            setLoading(true);

            // Create the new mail recipient
            await MailsService.createMailRecipient({ mail_address: mail });

            // Trigger the onCreate callback to refresh data
            onCreate();

            // Close the modal after creation
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // If the modal is not open, return null
    if (!open) return null;

    // Render the CreateMailRecipientModal component
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Créer un destinataire de mail'}
            handleSave={handleConfirmCreate}
            handleSaveLabel={'Créer'}
        >
            {/* Create mail recipient form */}
            <div className="create-mail-recipient-modal">
                {error && (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                )}

                {/* Mail address input */}
                <div className="mail-recipient--input">
                    <label>Adresse e-mail</label>
                    <input
                        type="mail"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        placeholder="Entrez une adresse e-mail"
                        disabled={loading}
                    />
                </div>
            </div>
        </CustomModal>
    );
};

// Prop types for the CreateMailRecipientModal component
CreateMailRecipientModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default CreateMailRecipientModal;
