import React, { createContext, useContext, useEffect, useState } from 'react';
import { Network } from '@capacitor/network';

// ConnectionContext: Manages the network connection status
const ConnectionContext = createContext();

/**
 * ConnectionProvider component: Provides the network connection state to its children.
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * 
 * This provider monitors the network connection status of the application.
 * It updates the state when the connection is lost or restored.
 */
export const ConnectionProvider = ({ children }) => {
  // State variable for online status
  const [isOnline, setIsOnline] = useState(true);

  /**
   * checkInitialStatus: Checks the initial network connection status.
   */
  const checkInitialStatus = async () => {
    try {
      // Get the current network status
      const status = await Network.getStatus();
      setIsOnline(status.connected); // Update the online status
    } catch (error) {
      console.error('Error checking initial network status:', error);
    }
  };

  useEffect(() => {
    // Check the initial network status on mount
    checkInitialStatus();

    // Listener for network status changes
    const listener = Network.addListener('networkStatusChange', (status) => {
      setIsOnline(status.connected); // Update the online status on change
    });

    // Cleanup the listener on component unmount
    return () => {
      listener.then(handle => handle.remove()); // Ensure correct removal of listener
    };
  }, []); // Run only once when the component mounts

  // Provide the network connection state to children components
  return (
    <ConnectionContext.Provider value={{ isOnline }}>
      {children}
    </ConnectionContext.Provider>
  );
};

/**
 * useConnection hook: Provides access to the connection state.
 * @returns {Object} - The connection state and related methods.
 * 
 * This hook allows components to access the network connection status.
 */
export const useConnection = () => useContext(ConnectionContext);