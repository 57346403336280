import ApiService from './ApiService';

/**
 * Service for handling mail-related API requests.
 */
class MailsService extends ApiService {

    /**
     * Fetches all mails.
     * @returns {Promise<Array>} A promise that resolves to an array of mail objects
     * @throws {Error} Throws an error if the request fails
     */
    async getMails() {
        try {
            // Fetch all mails
            const response = await this.request('GET', '/mail/read');
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches an mail recipient by their unique ID.
     * @param {number} mailRecipientId - The unique identifier for the mail recipient
     * @returns {Promise<Object>} A promise that resolves to the mail recipient object
     * @throws {Error} Throws an error if the request fails
     */
    async getMailRecipientById(mailRecipientId) {
        // Check if the mail recipient ID is provided
        if (!mailRecipientId) {
            console.error('Mail recipient ID is missing.');
            throw new Error('L\'identifiant du destinataire de mail est manquant.');
        }

        try {
            // Fetch the mail recipient details
            const response = await this.request('GET', `${mailRecipientId}/mail/read`);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Creates a new mail recipient.
     * @param {Object} payload - The data for the new mail recipient
     * @returns {Promise<Object>} A promise that resolves to the created mail recipient object
     * @throws {Error} Throws an error if the request fails
     */
    async createMailRecipient(payload) {
        // Check if the payload data is provided
        if (!payload) {
            console.error('Mail recipient data is missing.');
            throw new Error('Les données du destinataire de mail sont manquantes.');
        }

        try {
            // Create a new mail recipient using the provided data
            const response = await this.request('POST', '/mail/create', payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Updates an existing mail recipient.
     * @param {number} mailRecipientId - The unique identifier of the mail recipient
     * @param {Object} payload - The data to update in the mail recipient
     * @returns {Promise<Object>} A promise that resolves to the updated mail recipient object
     * @throws {Error} Throws an error if the request fails
     */
    async editMailRecipient(mailRecipientId, payload) {
        // Check if the mail recipient ID is provided
        if (!mailRecipientId) {
            console.error('Mail recipient ID is missing.');
            throw new Error('L\'identifiant du destinataire de mail est manquant.');
        }

        // Check if the payload data is provided
        if (!payload) {
            console.error('Mail recipient data is missing.');
            throw new Error('Les données du destinataire de mail sont manquantes.');
        }

        try {
            // Update the mail recipient based on the ID and payload
            const response = await this.request('PUT', `${mailRecipientId}/mail/update`, payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Deletes an mail recipient by its unique ID.
     * @param {number} mailRecipientId - The unique identifier of the mail recipient
     * @returns {Promise<void>} A promise that resolves when the mail recipient is deleted
     * @throws {Error} Throws an error if the request fails
     */
    async deleteMailRecipient(mailRecipientId) {
        // Check if the mail recipient ID is provided
        if (!mailRecipientId) {
            console.error('Mail recipient ID is missing.');
            throw new Error('L\'identifiant du destinataire de mail est manquant.');
        }

        try {
            // Delete the mail recipient based on the ID
            const response = await this.request('DELETE', `${mailRecipientId}/mail/delete`);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
}

const mailsService = new MailsService();
export default mailsService;
