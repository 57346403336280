import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AssignmentIcon from '@mui/icons-material/Assignment';
import DrawIcon from '@mui/icons-material/Draw';
import CommonButton from '../../buttons/common-button';
import InfoBox from '../../blocks/messages/info-box';

import SignaturesModal from './modals/modal--folder--signatures';

import AppointmentsService from '../../../services/AppointmentsService';
import FoldersService from '../../../services/FoldersService';

/**
 * AppointmentSignatures Component
 * 
 * This component displays the client's signature and the date it was signed.
 * If the signature is not available, it provides a message and a button to manage appointments.
 */
const AppointmentSignatures = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Navigation hook

    // State for folder ID
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(null);

    // State for appointments data
    const [hasUnlockedAppointment, setHasUnlockedAppointment] = useState(false);
    const [lastAppointmentUnlocked, setLastAppointmentUnlocked] = useState(null);

    /**
     * Fetches appointments and signature data based on folder.
     * Updates state based on the availability of the last unlocked appointment.
     * 
     * @returns {Promise<void>} - A promise that resolves when the data is fetched.
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder data based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                setError("Dossier introuvable");
                return;
            }

            // Fetch the appointments data based on the folder ID
            const appointmentsData = await AppointmentsService.getAppointmentsByFolderId(folder.id);

            // If the folder has no appointments, set the appointments state to an empty array
            if (!appointmentsData || appointmentsData.length === 0) {
                setHasUnlockedAppointment(false);
                return;
            }

            // Find the last unlocked appointment
            const lastUnlockedAppointment = appointmentsData.find(appointment => !appointment.is_locked);

            // If no unlocked appointment is found, set the state accordingly
            if (!lastUnlockedAppointment) {
                setHasUnlockedAppointment(false);
                return;
            }

            // Set the last unlocked appointment and update the state
            setLastAppointmentUnlocked(lastUnlockedAppointment);

            // Update the state with the fetched data
            setHasUnlockedAppointment(true);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Fetches appointments and signature data based on folderId.
     * Updates state based on the availability of the last unlocked appointment.
     */
    useEffect(() => {
        fetchData();
    }, []);

    // Display loading message while fetching data
    if (loading) {
        return (
            <div className="folder-section signatures-container w-100">
                <h2>Signatures</h2>
                Chargement...
            </div>
        );
    }

    // Display error message if data fetching fails
    if (error) {
        return (
            <div className="folder-section signatures-container w-100">
                <h2>Signatures</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    // Display message if no unlocked appointment is found
    if (!hasUnlockedAppointment || !lastAppointmentUnlocked) {
        return (
            <div className="folder-section signatures-container w-100">
                <h2>Signatures</h2>

                {/* Display a message when no unlocked appointment is found */}
                <InfoBox
                    type={'warning'}
                    disabledCloseBtn={true}
                    text={(
                        <React.Fragment>
                            <p className='w-100'><strong>Signature non disponible</strong></p>
                            <p className='w-100'>Vous ne pouvez pas signer le rapport d'intervention car il n'y a pas d'intervention en cours enregistrée.</p>
                            <CommonButton
                                icon={<AssignmentIcon />}
                                label="Gérer les interventions"
                                onClick={() => navigate(`/folders/${refFolder}/appointments`)}
                                square={true}
                                className={'warning-color'}
                            />
                        </React.Fragment>
                    )}
                />
            </div>
        );
    }

    // Return when the appointment is unlocked
    return (
        <React.Fragment>
            <div className="folder-section signatures-container w-100">
                <h2>Signatures</h2>

                {/* Display the last unlocked appointment data */}
                <InfoBox
                    type={'information'}
                    disabledCloseBtn={true}
                    text={(
                        <React.Fragment>
                            <p className='w-100'><strong>Rapport d'intervention du {lastAppointmentUnlocked?.appointment_date}</strong></p>
                            <p className='w-100'>Vous pouvez signer le rapport d'intervention en cliquant sur le bouton ci-dessous.</p><br />
                            <p className='w-100'><strong>Vérifiez que les informations suivantes sont correctes avant de signer :</strong></p>
                            <ul className='w-100'>
                                <li>Informations pannes</li>
                                <li>Interventions</li>
                                <li>Photos du dossier</li>
                                <li>Pièces du dossier</li>
                                <li>Paiements</li>
                            </ul>
                        </React.Fragment>
                    )}
                />
                <CommonButton
                    icon={<DrawIcon />}
                    label="Signer l'intervention"
                    square={true}
                    className={"no-side-margin signature-button"}
                    onClick={() => setModal(true)}
                />
            </div>

            { /* Modal for signatures */ }
            {modal && (
                <SignaturesModal
                    open={modal}
                    onClose={() => setModal(false)}
                    appointment={lastAppointmentUnlocked}
                />
            )}

        </React.Fragment>
    );
};

export default AppointmentSignatures;