import React from 'react'; 
import PropTypes from 'prop-types';

/**
 * SquareButton is a reusable button component used across the application.
 * It can display an icon (optional), a label, and triggers an action onClick.
 * If no onClick is provided, the button will still function without errors.
 * 
 * Props:
 * @param {ReactNode} [icon] - The optional icon to display inside the button.
 * @param {string} label - The text label for the button (used for accessibility as well).
 * @param {function} [onClick] - Optional function to execute when the button is clicked. Defaults to a no-op.
 * @param {boolean} mobileStickerEnabled - Flag to enable a special sticker class for mobile (default: false).
 * @param {boolean} square - Flag to make the button square (default: false).
 * @param {string} className - Additional classes to apply to the button (default: '').
 * @param {boolean} autoWidth - Flag to enable auto width for the button (default: false).
 * @param {boolean} disabled - Flag to disable the button (default: false).
 * 
 * Accessibility:
 * - Ensures the button has an accessible name using the aria-label.
 * - The button type is explicitly set to 'button' to avoid form submission issues in forms.
 */
const SquareButton = ({
    icon = null, // Default value for icon is null (optional)
    label,
    onClick = () => {}, // Default to a no-op function if no onClick is provided
    className = '', // Default to an empty string for additional classes
    autoWidth = false, // Default to false for auto width
    disabled = false, // Default to false for disabled button
}) => { 

    // Text to display when button is disabled
    const disabledText = 'Ce bouton est actuellement indisponible.';

    // If disabled, we prevent the click event
    const handleClick = disabled ? () => {} : onClick;

    // Render the button component
    return (
        <button
            className={`square-btn ${className} ${autoWidth ? 'auto-width' : ''} ${disabled ? 'app-disabled' : ''}`} 
            onClick={handleClick} // Disable the onClick handler if disabled
            type="button"
            aria-label={label} // Ensures the button is accessible with screen readers
            aria-pressed="false" // Optional, but can be set to true/false for toggle buttons
        >
            {/* Render the icon only if it's provided */}
            {icon}
            <span>{label}</span> {/* Ensures the label is displayed next to the icon */}
            {disabled && <div className="disabled-text">{disabledText}</div>} {/* Display disabled text if button is disabled */}
        </button>
    );
};

// Define PropTypes for better validation and debugging during development
SquareButton.propTypes = {
    icon: PropTypes.node, // icon can be any valid React node, optional
    label: PropTypes.string.isRequired, // label is required for accessibility
    onClick: PropTypes.func, // onClick is optional; defaults to a no-op
    className: PropTypes.string, // optional additional class names
    autoWidth: PropTypes.bool, // optional flag for enabling auto width
    disabled: PropTypes.bool, // optional flag to disable the button
};

export default SquareButton;