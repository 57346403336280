import ApiService from './ApiService';

/**
 * Service for handling folder-related API requests.
 */
class FoldersService extends ApiService {

    /**
     * Fetches folders based on the search query.
     * @param {string} $query - The search query to filter folders.
     * @returns {Promise<Object[]>} A promise that resolves to an array of folder objects.
     * @throws {Error} Throws an error if the request fails.
     */
    async getFoldersByQuery($query) {
        // Validate search query
        if (!$query) {
            console.error('Invalid search query');
            throw new Error('Requête de recherche invalide');
        }

        // Check if the search query is at least 3 characters long
        if ($query.length < 3) {
            console.error('Search query must be at least 3 characters long');
            throw new Error('La requête de recherche doit comporter au moins 3 caractères');
        }

        try {
            // Fetch folders based on the search query
            const response = await this.request('GET', '/folder/search?query=' + $query);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches a folder by its unique number
     * @param {string} folderNumber - The unique identifier for the folder.
     * @returns {Promise<Object>} A promise that resolves to the folder object.
     * @throws {Error} Throws an error if the request fails or if client details are missing.
     */
    async getFolderByNumber(folderNumber) {
        // Validate folder number
        if (!folderNumber) {
            console.error('Invalid folder number');
            throw new Error('Le numéro de dossier est invalide');
        }

        try {
            // Fetch the folder details using the folder number
            const folder = await this.request('GET', `${folderNumber}/folder/search-folder-number`);
            return folder;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches the appliance details by folder ID.
     * @param {number} folderId - The unique identifier of the folder
     * @returns {Promise<Object>} A promise that resolves to the appliance object
     * @throws {Error} Throws an error if the request fails
     */
    async getApplianceByFolderId(folderId) {
        // Validate folder ID
        if (typeof folderId !== 'number' || folderId <= 0) {
            console.error('Invalid folder ID');
            throw new Error('Identifiant de dossier invalide');
        }

        try {
            // Fetch the appliance details using the folder ID
            const appliance = await this.request('GET', `${folderId}/appliance/search-by-folder`);
            return appliance;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches the warranty details by folder ID.
     * This method first fetches the appliance by folderId and then uses the appliance ID to fetch warranty details.
     * 
     * @param {number} folderId - The unique identifier of the folder
     * @returns {Promise<Object>} A promise that resolves to the warranty object
     * @throws {Error} Throws an error if either request fails
     */
    async getWarrantyByFolderId(folderId) {
        // Validate folder ID
        if (typeof folderId !== 'number' || folderId <= 0) {
            console.error('Invalid folder ID');
            throw new Error('Identifiant de dossier invalide');
        }

        try {
            // Fetch the appliance details using the folder ID
            const warranty = await this.request('GET', `${folderId}/warranty/search-by-folder`);
            return warranty;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches the parts details by folder ID.
     * @param {number} folderId - The unique identifier of the folder
     * @returns {Promise<Object[]>} A promise that resolves to an array of part objects
     * @throws {Error} Throws an error if the request fails
     */
    async getPartsByFolderId(folderId) {
        // Validate folder ID
        if (!folderId) {
            console.error('Folder ID is required');
            throw new Error('L\'identifiant du dossier est requis');
        }

        try {
            // Fetch the part IDs and their associated amounts from the folder
            const folderParts = await this.request('GET', `/folder-part/read?id_folder=${folderId}`);

            // Filter the parts based on the folder ID
            if (!folderParts || folderParts.length === 0) {
                return [];
            }

            // Return the parts data
            return folderParts;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Edits the relationship details between a folder and a part in the folder-part table by folder ID and part ID.
     * @param {number} folderId - The unique identifier of the folder
     * @param {number} partId - The unique identifier of the part
     * @param {Object} payload - The data to update in the folder-part relationship (e.g., amount, position, etc.)
     * @returns {Promise<Object>} A promise that resolves to the updated folder-part relationship object
     * @throws {Error} Throws an error if the request fails
     */
    async editPartFolderById(folderId, partId, payload) {
        // Validate folder ID and part ID
        if (!folderId) {
            console.error('Folder ID is required');
            throw new Error('L\'identifiant du dossier est requis');
        }

        // Validate part ID
        if (!partId) {
            console.error('Part ID is required');
            throw new Error('L\'identifiant de la pièce est requis');
        }

        // Validate payload
        if (!payload || Object.keys(payload).length === 0) {
            console.error('Payload is required');
            throw new Error('Les données à mettre à jour sont requises');
        }

        try {
            // Update the folder-part relationship using the folder ID and part ID
            const response = await this.request('PUT', `${folderId}/${partId}/folder-part/update`, payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
}

const foldersService = new FoldersService();
export default foldersService;