import ApiService from './ApiService';

/**
 * Service for handling appointment-related API requests.
 */
class AppointmentsService extends ApiService {

    /**
     * Fetches all appointments.
     * @param {number} folderId - The unique identifier for the folder
     * @returns {Promise<Object[]>} A promise that resolves to an array of appointment objects
     * @throws {Error} Throws an error if the request fails
     */
    async getAppointmentsByFolderId(folderId) {
        // Check if the folder ID is provided
        if (!folderId) {
            console.error('Folder ID is missing.');
            throw new Error('L\'identifiant du dossier est manquant.');
        }

        try {
            // Fetch all appointments for the given folder ID
            const appointments = await this.request('GET', `/appointment/read?id_folder=${folderId}`);

            // Fetch the signature data for all appointments in parallel
            const updatedAppointments = await Promise.all(
                appointments.map(async (appointment) => {
                    const signature = await this.getSignatureById(appointment.id_signature);
                    return { ...appointment, signature };
                })
            );

            // Return the updated appointments
            return updatedAppointments;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Creates a new appointment.
     * @param {Object} payload - The data for the new appointment
     * @returns {Promise<Object>} A promise that resolves to the created appointment object
     * @throws {Error} Throws an error if the request fails
     */
    async createAppointment(payload) {
        // Check if the payload data is provided
        if (!payload) {
            console.error('Appointment data is missing.');
            throw new Error('Les données du rendez-vous sont manquantes.');
        }

        try {
            // Create a new appointment using the provided data
            const response = await this.request('POST', '/appointment/create', payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Updates an existing appointment by its unique ID.
     * @param {number} appointmentId - The unique identifier for the appointment
     * @param {Object} payload - The data to update in the appointment
     * @returns {Promise<Object>} A promise that resolves to the updated appointment object
     * @throws {Error} Throws an error if the request fails
     */
    async editAppointment(appointmentId, payload) {
        // Check if the appointment ID is provided
        if (!appointmentId) {
            console.error('Appointment ID is missing.');
            throw new Error('L\'identifiant du rendez-vous est manquant.');
        }

        // Check if the payload data is provided
        if (!payload) {
            console.error('Appointment data is missing.');
            throw new Error('Les données du rendez-vous sont manquantes.');
        }
        
        try {
            // Update the existing appointment using the provided data
            const response = await this.request('PUT', `${appointmentId}/appointment/update`, payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Deletes an appointment by its unique ID.
     * @param {number} appointmentId - The unique identifier for the appointment
     * @returns {Promise<void>} A promise that resolves when the appointment is deleted
     * @throws {Error} Throws an error if the request fails
     */
    async deleteAppointmentById(appointmentId) {
        // Check if the appointment ID is provided
        if (!appointmentId) {
            console.error('Appointment ID is missing.');
            throw new Error('L\'identifiant du rendez-vous est manquant.');
        }

        try {
            // Send a DELETE request to the endpoint for deleting an appointment
            const response = await this.request('DELETE', `${appointmentId}/appointment/delete`);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches all appointment actions.
     * @returns {Promise<Object[]>} A promise that resolves to an array of appointment action objects
     * @throws {Error} Throws an error if the request fails
     */
    async getAppointmentActions() {
        try {
            // Fetch all appointment actions using the updated route
            const actions = await this.request('GET', '/appointment-action/read'); // Assuming this endpoint fetches all actions
            return actions;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches an appointment action by its unique ID.
     * @param {number} actionId - The unique identifier for the action
     * @returns {Promise<Object>} A promise that resolves to the appointment action object
     * @throws {Error} Throws an error if the request fails
     */
    async getAppointmentActionById(actionId) {
        // Check if the action ID is provided
        if (!actionId) {
            console.error('Appointment action ID is missing.');
            throw new Error('L\'identifiant de l\'action de rendez-vous est manquant.');
        }

        try {
            // Fetch the appointment action data based on the action ID
            const action = await this.request('GET', `${actionId}/appointment-action/read`);
            return action;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Creates a new appointment action.
     * @param {Object} payload - The data for the new action
     * @returns {Promise<Object>} A promise that resolves to the created action object
     * @throws {Error} Throws an error if the request fails
     */
    async createAppointmentAction(payload) {
        // Check if the payload data is provided
        if (!payload) {
            console.error('Appointment action data is missing.');
            throw new Error('Les données de l\'action de rendez-vous sont manquantes.');
        }

        try {
            // Create a new appointment action using the provided data
            const response = await this.request('POST', '/appointment-action/create', payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Updates an existing appointment action by its unique ID.
     * @param {number} actionId - The unique identifier of the action
     * @param {Object} payload - The data to update in the action
     * @returns {Promise<Object>} A promise that resolves to the updated action object
     * @throws {Error} Throws an error if the request fails
     */
    async editAppointmentAction(actionId, payload) {
        // Check if the action ID is provided
        if (!actionId) {
            console.error('Appointment action ID is missing.');
            throw new Error('L\'identifiant de l\'action de rendez-vous est manquant.');
        }

        // Check if the payload data is provided
        if (!payload) {
            console.error('Appointment action data is missing.');
            throw new Error('Les données de l\'action de rendez-vous sont manquantes.');
        }

        try {
            // Update the existing appointment action using the provided data
            const response = await this.request('PUT', `${actionId}/appointment-action/update`, payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Deletes an action by its unique ID.
     * @param {number} actionId - The unique identifier of the action
     * @returns {Promise<void>} A promise that resolves when the action is deleted
     * @throws {Error} Throws an error if the request fails
     */
    async deleteAppointmentAction(actionId) {
        // Check if the action ID is provided
        if (!actionId) {
            console.error('Appointment action ID is missing.');
            throw new Error('L\'identifiant de l\'action de rendez-vous est manquant.');
        }

        try {
            // Send a DELETE request to the endpoint for deleting an appointment action
            const response = await this.request('DELETE', `${actionId}/appointment-action/delete`);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches all signatures for a given signature ID.
     * @param {number} signatureId - The unique identifier for the signature
     * @returns {Promise<Object[]>} A promise that resolves to an array of signature objects
     * @throws {Error} Throws an error if the request fails
     */
    async getSignatureById(signatureId) {
        // Check if the signature ID is provided
        if (!signatureId) {
            return null;
        }

        try {
            // Fetch the signature data based on the signature ID
            const signature = await this.request('GET', `${signatureId}/signature/download`);
            return signature;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Signs and locks an appointment by updating or creating a signature and locking the appointment
     * @param {number} appointmentId - The ID of the appointment to which the signature belongs
     * @param {Object} payload - The data for the signature to be updated or created
     * @throws {Error} Throws an error if the operation fails
     */
    async signAndLockAppointment(appointmentId, payload) {
        // Check if the appointment ID is provided
        if (!appointmentId) {
            console.error('Appointment ID is missing.');
            throw new Error('L\'identifiant du rendez-vous est manquant.');
        }

        // Check if the payload data is provided
        if (!payload) {
            console.error('Signature data is missing.');
            throw new Error('Les données de la signature sont manquantes.');
        }

        try {
            // Create or update the signature for the appointment
            const response = await this.request('POST', `/signature/create`, payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
}

const appointmentsService = new AppointmentsService();
export default appointmentsService;