import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';

import { PhotoCameraOutlined } from '@mui/icons-material';

import { useTitle } from '../context/header-context';
import { useFolder } from '../context/folder-context';

import Header from '../components/blocks/header';

/**
 * MediaOnlyLayout Component
 *
 * This component serves as a layout wrapper for the media upload functionality
 * within a folder, featuring a header, content, and a simplified action bar.
 *
 * @returns {JSX.Element} - Rendered component
 */
const MediaOnlyLayout = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate
    const location = useLocation(); // Hook to access the current location

    // Get the title and color from the folder context
    const { title } = useTitle();
    const { updateTitle, updateColor } = useFolder();

    // State to manage the active index of the action bar
    const [activeIndex, setActiveIndex] = useState(0);

    /**
     * Single navigation item for media upload.
     */
    const items = [
        {
            name: 'Médias du dossier',
            icon: <PhotoCameraOutlined />,
            path: `/folders/${refFolder}/media-only`,
            action() { navigate(`/folders/${refFolder}/media-only`); },
            backgroundColor: '#FFC107',
        }
    ];

    /**
     * Effect to update activeIndex and context based on the current URL path.
     */
    useEffect(() => {
        // Normalize the pathname by removing the trailing slash
        const normalizedPathname = location.pathname.replace(/\/$/, '');

        // Check if the current path matches the item's path
        const currentIndex = items.findIndex(item => normalizedPathname === item.path.replace(/\/$/, ''));

        // Update the active index and context if the path matches
        if (currentIndex !== -1) {
            setActiveIndex(currentIndex);
            updateTitle(items[currentIndex].name);
            updateColor(items[currentIndex].backgroundColor);
        }
    }, [location.pathname, items]);

    // Render the media-only layout with header, content, and action bar
    return (
        <div className='app-container small-padding-left'>
            { /* Header component with title */ }
            <Header title={title} />

            { /* Content with outlet */ }
            <div className='app-content' style={{ borderColor: items[activeIndex].backgroundColor }}>
                <Outlet />
            </div>
        </div>
    );
};

export default MediaOnlyLayout;