import React, { useState } from 'react'; // Import React

import Container from '../../components/blocks/container'; // Custom container component for layout
import ItemCard from '../../components/blocks/item-card'; // Custom item card component for displaying information

import { 
    PhoneAndroidOutlined, 
    InfoOutlined, 
    MemoryOutlined,
    VerifiedOutlined,
    PhonelinkEraseOutlined
} from '@mui/icons-material';

import { useDevice } from '../../modules/deviceModule'; // Use the custom hook for device info
import { useApp } from '../../modules/appModule';

import UsersService from '../../services/UsersService';

/**
 * InfoPage component: Displays information about the app and device.
 * It fetches the unique identifier, device name, and operating system of the device.
 */
const InfoPage = () => {
    // Use the custom hook to retrieve device information
    const { identifier, deviceName, operatingSystem } = useDevice();
    const { version } = useApp();

    const [error, setError] = useState(null); // State for error message

    /**
     * Dissociate the user from the device.
     * This function calls the UsersService to dissociate the user from the device.
     * It is an asynchronous function that awaits the UsersService call.
     * @returns {void}
     */
    const handleDissociateUser = async () => {
        try {
            // Call the UsersService to dissociate the device
            await UsersService.dissociateDevice();
        } catch (error) {
            setError(error?.message)
        }
    }

    /**
     * An array representing the settings and device information to be displayed.
     * Each object includes an icon, name, and description for the UI.
     */
    const settingsList = [
        {
            icon: <PhoneAndroidOutlined />,  // Icon for device identifier
            name: 'Identifiant unique',      // Label for identifier 
            description: `${identifier || 'Non disponible'}`, // Show identifier or "Non disponible" 
        },
        {
            icon: <InfoOutlined />,         // Icon for device name
            name: 'Nom de l\'appareil',     // Label for device name 
            description: `${deviceName || 'Non disponible'}`, // Show name or "Non disponible" 
        },
        {
            icon: <MemoryOutlined />,       // Icon for operating system
            name: 'Système d\'exploitation', // Label for OS 
            description: `${operatingSystem || 'Non disponible'}`, // Show OS or "Non disponible" 
        },
        {
            icon: <VerifiedOutlined />,  // Icon for app version
            name: 'Version de l\'application',       // Label for app version 
            description: `${version || 'Non disponible'}`,   // Show app version from package.json
        },
        {
            icon: <PhonelinkEraseOutlined />,  // Icon for app version
            name: 'Dissocier l\'utilisateur de l\'appareil', // Label for dissociate user from device
            description: "Cette action va dissocier l'utilisateur de la mémoire locale de votre appareil. Pour dissocier intégralement l'utilisateur de l'appareil, veuillez contacter un administrateur.",
            onClick: () => handleDissociateUser() // Click handler for dissociating user from device
        },
    ];

    /**
     * Renders the container with a list of device information.
     * Maps over the settings list to create individual ItemCard components for each setting.
     */
    return (
        <Container>
            { /* Display error message if there is an error */ }
            {error && <p className='error-message'>{error}</p>}

            <ul className='settings-block'>
                {settingsList.map((setting, index) => (
                    <ItemCard
                        key={index}           // Unique key for each setting
                        icon={setting.icon}   // Icon for the setting
                        name={setting.name}   // Name of the setting 
                        description={setting.description} // Description (the fetched device info)
                        onClick={setting.onClick} // Optional click handler
                        aria-labelledby={`info-setting-${index}-label`} // Associate with a label for accessibility
                        aria-describedby={`info-setting-${index}-description`} // Associate with a description for accessibility
                    />
                ))}
            </ul>
        </Container>
    );
}

export default InfoPage; // Export the InfoPage component