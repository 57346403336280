import ApiService from './ApiService';

/**
 * Service for handling permissions-related API requests.
 */
class PermissionsService extends ApiService {
    /**
     * Fetches all permissions.
     * @returns {Promise<Array>} A promise that resolves to an array of permission objects
     * @throws {Error} Throws an error if the request fails
     */
    async getPermissions() {
        try {
            // Fetch all permissions
            const permissions = await this.request('GET', '/role/read?display=true');
            return permissions;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches all permissions.
     * @returns {Promise<Array>} A promise that resolves to an array of permission objects
     * @throws {Error} Throws an error if the request fails
     */
    async getTemplatesUser() {
        try {
            // Fetch all permissions
            const templates = await this.request('GET', '/template/read');
            return templates;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
}

const permissionsService = new PermissionsService();
export default permissionsService;
