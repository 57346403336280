import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';
import MailsService from '../../../../services/MailsService';

/**
 * EditMailRecipientModal Component
 *
 * This component displays a modal for editing an mail recipient.
 * It takes `open`, `onClose`, `mailRecipientId`, and `onEdit` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose (function): Function to close the modal.
 * - mailRecipientId (number): The ID of the mail recipient to edit.
 * - onEdit (function): Function to handle the editing logic.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const EditMailRecipientModal = ({ open, onClose, mailRecipientId, onEdit }) => {
    // State to handle loading and error messages
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // State to handle mail
    const [mail, setMail] = useState('');

    /**
     * Fetch the current mails details
     * 
     * @returns {void}
     */
    const fetchMailRecipient = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the current mails details
            const data = await MailsService.getMailRecipientById(mailRecipientId);

            // Check if the mail was not found
            if (!data) {
                console.error('No mail recipient found');
                throw new Error('Aucun destinataire de mail trouvé');
            }

            // Set the mail in state
            setMail(data.mail_address);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Handles the edit confirmation
     * 
     * @returns {void}
     */
    const handleConfirmEdit = async () => {

        // Check if the mail address is empty
        if (!mail.trim()) {
            console.error('Mail address is required');
            setError('L\'adresse e-mail est requise.');
            return;
        }

        // Regular expression to validate email addresses
        const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        // Check if the email address is valid
        if (!mailRegex.test(mail)) {
            console.error('Invalid email address');
            setError('Adresse e-mail invalide.');
            return;
        }

        try {
            // Edit the mail recipient
            await MailsService.editMailRecipient(mailRecipientId, { mail_address: mail });

            // Trigger the onEdit callback
            onEdit();

            // Close the modal after editing
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // Fetch mail recipient details on component mount
    useEffect(() => {
        fetchMailRecipient();
    }, []);

    // If the modal is not open, return null
    if (!open || !mailRecipientId) return null;

    // Display a loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Modifier le destinataire de mail'}
            handleSave={handleConfirmEdit}
            handleSaveLabel={'Confirmer la modification'}
        >
            <div className="edit-mail-recipient-modal">
                {error && (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                )}
                <InfoBox
                    type="warning"
                    text={
                        <React.Fragment>
                            <p>Attention, modifier cette action peut affecter d'autres entités dépendantes.</p>
                        </React.Fragment>
                    }
                    disabledCloseBtn={true}
                />
                <div className="mail-recipient--input">
                    <label>Adresse e-mail</label>
                    <input
                        type="mail"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        placeholder="Entrez une adresse e-mail"
                    />
                </div>
            </div>
        </CustomModal>
    );
};

// Prop types for the EditMailRecipientModal component
EditMailRecipientModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    mailRecipientId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onEdit: PropTypes.func.isRequired,
};

export default EditMailRecipientModal;