import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';

import { Send, PictureAsPdf } from '@mui/icons-material';

import CommonButton from '../../buttons/common-button';
import FilesService from '../../../services/FilesService';
import FoldersService from '../../../services/FoldersService';
import SendFolderModal from './modals/modal--folder--send-folder';
import InfoBox from '../../blocks/messages/info-box';

/**
 * OverviewTools Component
 * 
 * This component provides action buttons to interact with a report.
 */
const OverviewTools = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    const [folder, setFolder] = useState(null); // State to store the folder details

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [succes, setSucces] = useState(null);
    const [modal, setModal] = useState(false);

    /**
     * Fetches the folder data from the server.
     * This function fetches the folder data based on the folder number.
     * If the folder is not found, an error is thrown.
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                setError(new Error('Dossier introuvable'));
                return;
            }

            setFolder(folder);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    }

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Handles sending the report by e-mail.
     * This function triggers the logic to send the report by e-mail.
     */
    const handleSendEmail = () => {
        setModal(true);
    };

    /**
     * Handles downloading the report as a PDF.
     * This function triggers the logic to download the report as a PDF.
     */
    const handleDownloadPDF = async () => {
        try {
            // Download the folder contents as a PDF
            const response = await FilesService.downloadFolder(folder);

            // Log an error if the response is invalid
            if (!response) {
                setError('Erreur lors du téléchargement du rapport en PDF');
                return;
            }
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // Display a success message when the report is sent by e-mail
    useEffect(() => {
        if (succes) {
            setTimeout(() => {
                setSucces(null);
            }, 5000);
        }
    }, [succes]);

    // Return null if the folder data is loading or not available
    if (loading || !folder || error) return;

    // Render the overview tools component
    return (
        <React.Fragment>

            { /* Display error message */}
            { succes && (<InfoBox type="validation" text={succes} />)}

            { /* Display overview tools */}
            <div className="overview-tools">

                { /* Action buttons */}
                <div className="action-buttons">
                    <CommonButton
                        onClick={handleSendEmail}
                        label="Envoyer par e-mail"
                        square={true}
                        icon={<Send />}
                        className="send-email"
                    />
                    <CommonButton
                        onClick={handleDownloadPDF}
                        label="Télécharger en PDF"
                        square={true}
                        icon={<PictureAsPdf />}
                        className="download-pdf"
                    />
                </div>
            </div>

            { modal && (
                <SendFolderModal
                    open={modal}
                    onClose={() => setModal(false)}
                    folder={folder}
                    onSend={() => setSucces('Le dossier a été envoyé par e-mail avec succès')}
                />
            )}
        </React.Fragment>
    );
};

// Prop types for OverviewTools component
OverviewTools.propTypes = {
    overviewRef: PropTypes.object.isRequired,
};

export default OverviewTools;