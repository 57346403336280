import React, { useState, useEffect } from 'react';

import {
    Edit,
    DeleteOutline,
    Add
} from '@mui/icons-material';

import MailsService from '../../../services/MailsService';

import Container from '../../blocks/container';
import CommonButton from '../../buttons/common-button';
import InfoBox from '../../blocks/messages/info-box';
import CreateMailRecipientModal from './modals/modal--admin--create-mail-recipients';
import DeleteMailRecipientModal from './modals/modal--admin--delete-mail-recipients';
import EditMailRecipientModal from './modals/modal--admin--edit-mail-recipients';

/**
 * MailRecipientsTable Component
 *
 * This component renders a table of mails and allows the user to manage them.
 * @returns {JSX.Element}
 */
const MailRecipientsTable = () => {
    // State for loading, error, and modal
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State for mails
    const [mails, setMails] = useState([]);

    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    /**
     * Fetches the mails from the API.
     * Sets the loading state and handles errors.
     * @returns {Promise<void>} A promise that resolves when the data is fetched.
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchMails = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the mails
            const data = await MailsService.getMails();

            // Set the mails state
            setMails(data);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // Fetch mails on component mount
    useEffect(() => {
        fetchMails();
    }, []);

    // Display loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    // Display error message if fetching fails
    if (error) {
        return (
            <InfoBox
                type={'error'}
                disabledCloseBtn={true}
                text={error}
            />
        );
    }

    // Pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentMails = mails.slice(startIndex, endIndex);

    const totalPages = Math.ceil(mails.length / itemsPerPage);

    // Pagination handlers
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const getPageRange = () => {
        const range = [];
        const start = Math.max(1, currentPage - 1);
        const end = Math.min(totalPages, currentPage + 1);

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        return range;
    };

    // Modal handlers for deleting an existing mail
    const handleDelete = (mailRecipientId) => {
        setModal({ type: 'delete', mailRecipientId: mailRecipientId });
    };

    // Modal handlers for editing an existing mail
    const handleEdit = (mailRecipientId) => {
        setModal({ type: 'edit', mailRecipientId: mailRecipientId });
    };

    // Modal handlers for adding a new mail
    const handleAdd = () => {
        setModal({ type: 'add' });
    };

    // Component to be rendered
    return (
        <React.Fragment>
            <Container className="admin-mail-recipients-manager auto-height">

                { /* Title */ }
                <h2>Gestion des destinataires pour l'envoi des rapports</h2>

                { /* Info box */ }
                <InfoBox
                    text={'La liste des destinataires de mail pour l\'envoi des rapports, sera disponible pour que le technicien puisse les sélectionner lors de la validation d\'une intervention.'}
                    disabledCloseBtn={true}
                />

                {mails.length > 0 ? (
                    <div className="mail-recipients-list">

                        { /* Table of mail recipients */ }
                        <table className="mail-recipients-table">
                            <thead>
                                <tr>
                                    <th>Mail</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentMails.map((recipient) => (
                                    <tr key={recipient.id}>
                                        <td>{recipient.mail_address}</td>
                                        <td>
                                            <button
                                                onClick={() => handleEdit(recipient.id)}
                                                className="mail-recipients-table-btn"
                                            >
                                                <Edit />
                                            </button>
                                            <button
                                                onClick={() => handleDelete(recipient.id)}
                                                className="mail-recipients-table-btn"
                                            >
                                                <DeleteOutline />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        { /* Pagination */ }
                        <div className="pagination">
                            <button
                                onClick={() => setCurrentPage(1)}
                                disabled={currentPage === 1}
                                className="first pagination-btn"
                            >
                                Première
                            </button>
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className="back pagination-btn"
                            >
                                Précédent
                            </button>
                            {getPageRange().map((page) => (
                                <button
                                    key={page}
                                    onClick={() => setCurrentPage(page)}
                                    className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                                >
                                    {page}
                                </button>
                            ))}
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className="next pagination-btn"
                            >
                                Suivant
                            </button>
                            <button
                                onClick={() => setCurrentPage(totalPages)}
                                disabled={currentPage === totalPages}
                                className="last pagination-btn"
                            >
                                Dernière
                            </button>
                        </div>
                    </div>
                ) : (
                    <p className='no-mail-recipients'>Aucun destinataire de mail n'a été trouvé.</p>
                )}

                {/* Add new action button */}
                <CommonButton
                    label="Ajouter un destinataire"
                    icon={<Add />}
                    onClick={() => handleAdd()}
                />
            </Container>

            { /* Modals */ }
            {modal && modal.type === 'delete' && (
                <DeleteMailRecipientModal open={true} onClose={() => setModal(null)} mailRecipientId={modal.mailRecipientId} onDelete={() => fetchMails()} />
            )}

            {modal && modal.type === 'edit' && (
                <EditMailRecipientModal open={true} onClose={() => setModal(null)} mailRecipientId={modal.mailRecipientId} onEdit={() => fetchMails()} />
            )}

            {modal && modal.type === 'add' && (
                <CreateMailRecipientModal open={true} onClose={() => setModal(null)} onCreate={() => fetchMails()} />
            )}
        </React.Fragment>
    );
};

export default MailRecipientsTable;