import ApiService from './ApiService';

/**
 * Service for handling files-related API requests.
 */
class FilesService extends ApiService {

    /**
     * Downloads folder contents.
     * @param {object} folder - The folder object to download.
     * @returns {Promise<Object>} A promise that resolves to the folder contents.
     * @throws {Error} Throws an error if the request fails.
     */
    async downloadFolder(folder) {

        // Validate folder object
        if (!folder) {
            console.error('Invalid folder object');
            throw new Error('Le dossier est invalide');
        }

        // Get the folder ID
        const folderId = folder.id;

        // Validate folder ID
        if (!folderId) {
            console.error('Invalid folder ID');
            throw new Error('Identifiant de dossier invalide');
        }

        if (!folder.folder_number) {
            console.error('Invalid folder number');
            throw new Error('Le numéro de dossier est invalide');
        }

        try {
            // Fetch the folder contents using the folder ID and return the data as a binary file
            const pdfContent = await this.request('GET', `template-filler/read?id_folder=${folderId}`, null ,true);

            // Create a new Blob object from the binary file
            const blob = new Blob([pdfContent], { type: 'application/pdf' });

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'rapport_' + folder.folder_number + '.pdf';
            link.click();
            
            // Return success
            return true;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Send the folder contents by email.
     * @param {object} payload - The data for sending the folder by email.
     * @returns {Promise<Object>} A promise that resolves to the email response.
     * @throws {Error} Throws an error if the request fails.
     */
    async sendFolderByEmail(payload) {
        // Validate folder ID
        if (!payload || !payload.id_folder || !payload.id_mail_address) {
            console.error('Invalid folder ID or mail address');
            throw new Error('Identifiant de dossier ou adresse mail invalide');
        }

        try {
            // Send the folder contents by email
            await this.request('GET', `template-filler/send?id_folder=${payload.id_folder}&id_mail_address=${payload.id_mail_address}`);

            // Return the email response
            return true;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
}

const filesService = new FilesService();
export default filesService;
