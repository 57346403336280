import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import FoldersService from '../../../services/FoldersService';

import { Add } from '@mui/icons-material';

import InfoBox from '../../blocks/messages/info-box';
import CommonButton from '../../buttons/common-button';
import AddNewPaymentModal from './modals/modal--folder--add-new-payment';
import AppointmentsService from '../../../services/AppointmentsService';

/**
 * PaymentsOverview component to display diagnostic and invoice payments for a given folder.
 * @param {boolean} showEditButton - Flag to conditionally render edit button for payments
 * @returns {JSX.Element} - Rendered payments overview component
 */
const PaymentsOverview = ({ showEditButton = false }) => {
    // Get the folder number from the URL
    const { refFolder } = useParams();

    // State for folder data
    const [folder, setFolder] = useState(null);

    // State for bonus Quali Repar
    const [bonusQualiRepar, setBonusQualiRepar] = useState(null);

    // State for folder ID
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(null);
    
    // State to store fetched payment data
    const [paymentsData, setPaymentsData] = useState([]);

    /**
     * Fetches data for folder payments and appointments.
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder data based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                setError("Dossier introuvable");
                return;
            }

            // Set folder state with fetched data
            setFolder(folder);

            // Fetch the appointments data based on the folder ID
            const appointmentsData = await AppointmentsService.getAppointmentsByFolderId(folder.id);

            // If the folder has appointments and is_quali_repar is true, set bonus Quali Repar
            if (appointmentsData[0]) {
                const firstAppointment = appointmentsData[0];

                if (firstAppointment.is_quali_repar && !folder.quote_payment) {
                    setBonusQualiRepar(firstAppointment.quali_repar_amount);
                }
            }
            
            // Update payments state with fetched data
            setPaymentsData(folder.payments);
        } catch (error) {
            setError(error?.message || 'Une erreur inattendue est survenue');
        } finally {
            setLoading(false); // Set loading to false once data fetch is complete
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Render loading message if data is still being fetched
    if (loading) {
        return (
            <div className="folder-section payments-container w-100">
                <h2>Règlements</h2>
                Chargement des données...
            </div>
        );
    }

    // Render error message if there was an error fetching data
    if (error) {
        return (
            <div className="folder-section payments-container w-100">
                <h2>Règlements</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    if (!folder) {
        return (
            <div className="folder-section payments-container w-100">
                <h2>Règlements</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text="Dossier introuvable"
                />
            </div>
        );
    }

    // Render payments overview when data is successfully fetched
    return (
        <React.Fragment>
            <div className="folder-section payments-container w-100">
                <h2>Règlements</h2>

                {/* Payments Table */}
                <table className="payments-table w-100">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Mode de paiement</th>
                            <th>Montant</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentsData.map((payment, index) => (
                            <tr key={index}>
                                <td>{payment.payment_date ? new Date(payment.payment_date).toLocaleString() : '-'}</td>
                                <td>{payment.payment_mode}</td>
                                <td>{payment.amount} €</td>
                            </tr>
                        ))}

                        {/* Bonus Quali Repar */}
                        { bonusQualiRepar && (
                            <tr className="bonus-row">
                                <td colSpan="2">
                                    <p><strong>Bonus QUALIREPAR</strong> <i>(A déduire du montant disponible dans "Informations complémentaires" ci-dessous)</i></p>   
                                </td>
                                <td>{bonusQualiRepar} €</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2"><strong>Total réglé</strong></td>
                            <td>
                                <strong>
                                    {!folder.quote_payment ? 
                                        paymentsData.reduce((total, payment) => total + payment.amount, 0) + (bonusQualiRepar || 0) + ' €'
                                        : 
                                        paymentsData.reduce((total, payment) => total + payment.amount, 0) + ' €'
                                    }
                                </strong>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                { /* Additional Information Table */ }
                { showEditButton && (
                    <table className="payments-table w-50">
                        <thead>
                            <tr>
                                <th>Informations</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Informations complémentaires</td>
                                <td>{folder?.additional_informations || '-'}</td>
                            </tr>
                            <tr>
                                <td>Montant QUALIREPAR</td>
                                <td>{bonusQualiRepar || '-'} €</td>
                            </tr>
                            <tr>
                                <td>Accompte</td>
                                <td>{folder.down_payment || '-'} €</td>
                            </tr>
                            <tr>
                                <td>Montant devis</td>
                                <td>{folder.quote_payment || '-'} €</td>
                            </tr>
                        </tbody>
                    </table>
                )}

                { showEditButton && (
                    <CommonButton
                        className="edit-button"
                        onClick={() => setModal(true)}
                        label="Ajouter un règlement"
                        icon={<Add />}
                        square={true}
                    />
                )}
            </div>

            {/* Add Payment Modal */}
            {modal && (
                <AddNewPaymentModal
                    open={modal}
                    onClose={() => setModal(false)}
                    idFolder={folder.id}
                    onAddPayment={fetchData}
                />
            )}
        </React.Fragment>
    );
};

// Prop types for PaymentsOverview component
PaymentsOverview.propTypes = {
    showEditButton: PropTypes.bool,
};

export default PaymentsOverview;
