import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

import FilesService from '../../../../services/FilesService';
import MailsService from '../../../../services/MailsService';

/**
 * SendFolderModal Component
 * 
 * This component displays a modal for sending a folder by email.
 * It takes `open`, `onClose`, `folder`, and `onSend` as props.
 * 
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose (function): Function to close the modal.
 * - folder (object): The folder to send by email.
 * - onSend (function): Function to handle the sending logic.
 * 
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const SendFolderModal = ({ open, onClose, folder, onSend }) => {

    // State to handle the selected group category
    const [mailRecipients, setMailRecipients] = useState([]);
    const [currentMailRecipient, setCurrentMailRecipient] = useState(null);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Fetch the mail recipients
     * 
     * @returns {void}
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the mail recipients
            const data = await MailsService.getMails();

            // Check if the mail was not found
            if (!data) {
                setError('Aucun destinataire de mail trouvé');
                return;
            }

            // Set the mail recipients in state
            setMailRecipients(data);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Handles the confirmation to send the folder by email
     * 
     * @returns {void}
     */
    const handleConfirm = async () => {
        // Set loading state to true
        const payload = {
            id_folder: folder.id,
            id_mail_address: currentMailRecipient
        }

        // Check if the mail recipient is selected
        if (!payload.id_mail_address) {
            setError('Destinataire du mail introuvable');
            return;
        }

        // Check if the folder is selected
        if (!payload.id_folder) {
            setError('Dossier introuvable');
            return;
        }

        try {
            // Send the folder contents by email
            const response = FilesService.sendFolderByEmail(payload);

            // Log an error if the response is invalid
            if (!response) {
                setError('Erreur lors de l\'envoi du rapport par e-mail');
                return;
            }

            // Trigger the onSend callback to refresh data
            onSend();
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    // Return null if the modal is not open
    if (!open) return null;

    // Render the modal
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Envoyer le dossier par mail'}
            handleSave={handleConfirm}
            handleSaveLabel="Envoyer"
            onCloseLabel={'Annuler'}
        >
            { /* Display an error message if an error occurs */ }
            {error && <InfoBox type="error" text={error} disabledCloseBtn={true} />}

            { /* Display a loading message while fetching data */ }
            {loading && <div>Chargement...</div>}

            { /* Select the mail recipient */ }
            <div className="send-folder--select">
                <label>Destinataire du mail</label>
                <select
                    value={currentMailRecipient || ''}
                    onChange={(e) => setCurrentMailRecipient(e.target.value)}
                >
                    <option value="">Sélectionnez un destinataire</option>
                    {mailRecipients.map(recipient => (
                        <option key={recipient.id} value={recipient.id}>
                            {recipient.mail_address}
                        </option>
                    ))}
                </select>
            </div>
        </CustomModal>
    );
};

// Prop types for the SendFolderModal component
SendFolderModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    folder: PropTypes.object.isRequired,
    onSend: PropTypes.func
};

export default SendFolderModal;
