import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import FoldersService from '../../services/FoldersService';

import { Close, Search } from '@mui/icons-material';

/**
 * SearchBar Component
 * 
 * This component displays a search bar that allows users to search for folders by folder number or client name.
 * The search bar displays results based on the search query and allows users to navigate to the folder details page.
 * 
 * Props:
 * - onClose (function): The function to close the search bar.
 * - open (boolean): The state to determine if the search bar is open.
 * 
 * @param {Object} props - Component properties.
 * @param {Function} props.onClose - The function to close the search bar.
 * 
 * @returns {JSX.Element} The search bar component.
 */
const SearchBar = ({ onClose, open }) => {
    // Hook to programmatically navigate
    const navigate = useNavigate();

    // State to hold the search query
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState([]);
    const [isActive, setIsActive] = useState(false);  // State to track if the search bar is active

    // State to handle loading and error messages
    const [error, setError] = useState(null);

    // useEffect Hook
    useEffect(() => {
        if (!open){
            setSearchQuery('');  // Clear the search query when the search bar is opened
        }
    }, [open]);

    /**
     * Fetch folders based on the search query.
     * This function is independent of the useEffect.
     */
    const fetchFolders = useCallback(async (query) => {
        if (query.length < 3) {
            setResults([]);
            setError(null);
            return;
        }

        try {
            const filteredFolders = await FoldersService.getFoldersByQuery(query);
            setResults(filteredFolders || []);
            setError(null);
        } catch (err) {
            setError(err.message || 'Une erreur inattendue est survenue');
            setResults([]);
        }
    }, []);

    // Clear search query when the search bar is closed
    useEffect(() => {
        if (!open) {
            setSearchQuery('');
            setResults([]);
            setError(null);
        }
    }, [open]);

    // Monitor searchQuery changes and fetch folders
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchFolders(searchQuery); // Call the independent function
        }, 300); // Add a debounce for better performance

        return () => clearTimeout(timer); // Clear the timer on query change
    }, [searchQuery, fetchFolders]);

    /** 
     * handleResultClick
     * Navigates to the folder details page when a result is clicked.
     * @param {Object} folder - The folder object clicked by the user.
     */
    const handleResultClick = (folder) => {
        navigate(`/folders/${folder.folder_number}`);  // Navigate to the folder details page
    };

    const handleFocus = () => setIsActive(true);
    const handleBlur = () => setIsActive(false);

    // Return the JSX for the SearchBar component
    return (
        <div className={`search-bar-container ${isActive ? 'active' : ''} ${open ? 'open' : ''}`}>

            {/* Close button */}
            <button className='close-button' onClick={onClose}>
                FERMER
                <Close />
            </button>

            {/* Search bar input */}
            <div className='search-bar' role="search" aria-label="Search folders">
                <div className="search-title">
                    <Search />
                    <p className='label'>Rechercher un dossier</p>
                </div>
                <input
                    type="text"
                    placeholder="Numéro de dossier | Nom de client"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}  // Update the search query
                    className="search-input"
                    aria-controls="search-results"
                    onFocus={handleFocus}  // Trigger the focus event handler
                    onBlur={handleBlur}    // Trigger the blur event handler
                />
            </div>

            { /* Display error message if there is an error */ }
            {error && <p className='error-message'>{error}</p>}

            {/* Display search results */}
            {results && results.length > 0 && (
                <ul className="search-results" role="listbox" id="search-results" aria-label="Search results">
                    {results.map((folder, index) => (
                        <li
                            key={folder.folder_number + index}
                            className="search-result-item"
                            role="option"
                            aria-selected={false}
                            tabIndex={0}
                            onClick={() => handleResultClick(folder)}  // Navigate on click
                            onKeyDown={(e) => e.key === 'Enter' && handleResultClick(folder)}  // Navigate on Enter key press
                        >
                            <p className='folder-number'>{folder.folder_number || 'N/A'}</p>
                            <p className='client-name'>{folder.client?.complete_name || 'N/A'}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchBar;