import React from 'react'; // Import React

import Container from '../../components/blocks/container'; // Container component for layout
import ItemOption from '../../components/blocks/item-option'; // Component for individual item options

import { useGallery } from '../../context/gallery-context'; // Custom hook for gallery settings

/**
 * ConfigPage component: Displays the configuration options for the app.
 */
const ConfigPage = () => {
    const {
        timestamp, // State for timestamp setting
        geolocation, // State for geolocation setting
        toggleTimestamp, // Function to toggle timestamp
        toggleGeolocation, // Function to toggle geolocation
    } = useGallery(); // Retrieve settings from the gallery context

    /**
     * List of settings related to photo configurations.
     * Each object includes the name, description, toggle function, and initial state.
     */
    const settingsList = [
        {
            name: 'Horodatage sur la photo', // Label for timestamp setting
            description: timestamp ? 'Activé' : 'Désactivé', // Display current status
            onToggle: toggleTimestamp, // Function to toggle the setting
            initialState: timestamp // Initial state of the setting
        },
        {
            name: 'Géolocalisation sur la photo', // Label for geolocation setting
            description: geolocation ? 'Activé' : 'Désactivé', // Display current status
            onToggle: toggleGeolocation, // Function to toggle the setting
            initialState: geolocation // Initial state of the setting
        },
    ];

    /**
     * Renders the configuration page with photo settings and folder settings.
     * Maps over settings lists to create individual ItemOption and ItemCard components.
     */
    return (
        <Container>
            <h2>Configuration de l'appareil photo</h2> {/* Section title for camera settings */}
            <ul className='settings-block'>
                {settingsList.map((setting, index) => (
                    <ItemOption
                        key={index}   // Unique key for each setting
                        name={setting.name}  // Name of the setting 
                        description={setting.description} // Description of the setting
                        onToggle={setting.onToggle} // Toggle function for changing the setting
                        initialState={setting.initialState} // Initial state of the setting
                        aria-labelledby={`setting-${index}-label`} // Associate with a label for accessibility
                        aria-describedby={`setting-${index}-description`} // Associate with a description for accessibility
                    />
                ))}
            </ul>
        </Container>
    );
}

export default ConfigPage; // Export the ConfigPage component