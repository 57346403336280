import ApiService from './ApiService';

/**
 * Service for handling user-related API requests.
 */
class UsersService extends ApiService {
    /**
     * Fetches all users.
     * @returns {Promise<Array>} A promise that resolves to an array of user objects
     * @throws {Error} Throws an error if the request fails
     */
    async getUsers() {
        try {
            // Fetch all users
            const users = await this.request('GET', '/user/read');
            return users;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches a user by their unique ID.
     * @param {number} userId - The unique identifier for the user
     * @returns {Promise<Object>} A promise that resolves to the user object
     * @throws {Error} Throws an error if the request fails
     */
    async getUserById(userId) {
        // Validate user ID
        if (!userId) {
            console.error('Invalid user ID');
            throw new Error('L\'identifiant de l\'utilisateur est invalide.');
        }

        try {
            // Fetch user details
            const user = await this.request('GET', `${userId}/user/read`);
            return user;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Creates a new user with the provided data and permissions.
     * @param {Object} userData - The data for the new user
     * @returns {Promise<Object>} A promise that resolves to the created user
     * @throws {Error} Throws an error if the request fails
     */
    async createUser(userData) {
        // Get the current date in the required format (DD/MM/YYYY)
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('fr-FR');

        // Validate user data
        if (!userData) {
            console.error('Invalid user data');
            throw new Error('Les données de l\'utilisateur sont invalides.');
        }

        // Prepare the user data payload with the new fields
        const payload = {
            ...userData,
            created_at: formattedDate,
            updated_at: formattedDate,
        };

        try {
            // Create the user with the provided data
            const createdUser = await this.request('POST', `/user/create`, payload);
            return createdUser;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Edits a user's data and permissions.
     * @param {number} userId - The ID of the user to edit
     * @param {Object} userData - The new data for the user
     * @returns {Promise<Object>} A promise that resolves to the updated user
     * @throws {Error} Throws an error if the request fails
     */
    async editUser(userId, userData) {
        // Get the current date in the required format (DD/MM/YYYY)
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('fr-FR');

        // Validate user ID
        if (!userId) {
            console.error('Invalid user ID');
            throw new Error('L\'identifiant de l\'utilisateur est invalide.');
        }

        // Validate user data
        if (!userData) {
            console.error('Invalid user data');
            throw new Error('Les données de l\'utilisateur sont invalides.');
        }

        // Prepare the user data payload with the new fields
        const payload = {
            ...userData,
            updated_at: formattedDate,
        };

        try {
            // Update the user data
            const updatedUser = await this.request('PUT', `/${userId}/user/update`, payload);
            return updatedUser;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Edits a user's status (active or inactive).
     * @param {number} userId - The ID of the user to edit
     * @returns {Promise<Object>} A promise that resolves to the updated user
     * @throws {Error} Throws an error if the request fails
     */
    async editUserStatus(userId) {
        try {
            // Validate user ID
            const user = await this.request('PUT', `/${userId}/user/disable`);
            return user;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Edits a user's device identifier.
     * @param {number} userId - The ID of the user to edit
     * @param {string} deviceIdentifier - The new device identifier
     * @returns {Promise<Object>} A promise that resolves to the updated user
     * @throws {Error} Throws an error if the request fails
     */
    async editDeviceIdentifier(userId, deviceIdentifier) {
        // Validate user ID
        if (!userId) {
            console.error('Invalid user ID');
            throw new Error('L\'identifiant de l\'utilisateur est invalide.');
        }

        // Validate device identifier
        if (!deviceIdentifier) {
            console.error('Invalid device identifier');
            throw new Error('L\'identifiant de l\'appareil est invalide.');
        }

        // Prepare the device identifier payload
        const payload = {
            device_identifier: deviceIdentifier,
        };

        try {
            // Update the user's device identifier
            const updatedUser = await this.request('PUT', `/${userId}/user/update`, payload);
            return updatedUser;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Links the device to the user using the provided session token.
     * @param {string} sessionToken - The session token to link the device to the user
     * @param {string} deviceIdentifier - The unique identifier of the device
     * @throws {Error} Throws an error if the request fails
     * @returns {Promise<void>} A promise that resolves when the device is linked to the user
     */
    async connect(sessionToken, deviceIdentifier) {
        try {
            // Validate session token and device identifier
            await this.login(sessionToken, deviceIdentifier);
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Dissociates the device from the user.
     * @throws {Error} Throws an error if the request fails
     * @returns {Promise<void>} A promise that resolves when the device is dissociated from the user
     */
    async dissociateDevice() {
        try {
            // Reset the storage session
            this.resetStorageSession();
        } catch (error) {
            throw new Error(error?.message);
        }
    }
    
    /**
     * Temporarily returns the connected user object.
     * @returns {Object} The connected user object
     */
    async getUserConnected() {
        try {
            // Fetch the user using the session token
            const user = await this.request('GET', '/user/current');
            return user;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
}

const usersService = new UsersService();
export default usersService;