import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { RotateRight } from '@mui/icons-material';

import MediasService from '../../../services/MediasService';

import CommonButton from '../../buttons/common-button';
import InfoBox from '../../blocks/messages/info-box';

/**
 * GallerySection Component
 * 
 * This component renders a gallery of media files from a folder.
 * @param {object} folder - The folder to fetch media files from
 * @returns {JSX.Element}
 */
const GallerySection = ({ folder }) => {
    // State variable for gallery media
    const [galleryMedia, setGalleryMedia] = useState([]);

    // State variables for loading and error handling
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State for the zoomed-in media
    const [zoomedMedia, setZoomedMedia] = useState(null);

    /**
     * Fetches the media files from the folder.
     * Sets the loading state and handles errors.
     * @returns {Promise<void>} A promise that resolves when the data is fetched.
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchGalleryMedia = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch media files from the folder
            const media = await MediasService.getMediaByFolderId(folder.id);

            // Update the gallery media state with the fetched media
            setGalleryMedia(media);
        } catch (error) {
            setError(error?.message || 'Une erreur inattendue est survenue');
        } finally {
            setLoading(false);
        }
    };

    /**
     * Renders the media file based on its type.
     * @param {string} media - The media file to render
     * @returns {JSX.Element} The rendered media file
     */
    const renderMedia = (media, index) => {
        
        // Check if the media is an image or a video
        const mediaData = media[0];

        const handleClick = () => {
            setZoomedMedia(media); // Set the clicked media for zooming
        };

        if (mediaData.startsWith('data:image')) {
            // Display an image if the media is an image
            return (
                <img
                    key={`media_${index}`}
                    src={media}
                    alt={`Média_${index}`}
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                />
            );
        } else if (mediaData.startsWith('data:video')) {
            // Display a video if the media is a video
            return (
                <video
                    key={`media_${index}`}
                    controls
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                >
                    <source src={media} type="video/mp4" />
                    Votre navigateur ne supporte pas la lecture des vidéos.
                </video>
            );
        }
        return null;
    };

    // Close zoomed-in media
    const closeZoom = () => {
        setZoomedMedia(null);
    };

    // Fetch media files on component mount
    return (
        <div className="media-gallery--section">
            {/* Display the gallery title */}
            <h2>Historique des médias</h2>

            {/* Display a loading spinner if loading is true */}
            {loading && <CircularProgress />}

            {/* Display an error message if an error occurs */}
            {error && <InfoBox type="error" text={error} />}

            <div className="gallery-media">
                {/* Display a message if no media is found */}
                {galleryMedia.length === 0 && !loading && (
                    <InfoBox
                        type="information"
                        text={
                            <p>Appuyer sur le bouton <strong>Charger les médias</strong> pour rafraîchir la liste des médias.</p>
                        }
                        disabledCloseBtn={true}
                    />
                )}

                {/* Map through the media array and render images or videos */}
                {galleryMedia.map((media, index) => renderMedia(media, index))}
            </div>

            <div className='gallery-actions'>
                {/* Display a button to fetch media files */}
                <CommonButton
                    icon={<RotateRight />}
                    label="Charger les médias"
                    onClick={fetchGalleryMedia}
                />
            </div>

            {/* Modal for zoomed-in media */}
            {zoomedMedia && (
                <div className="zoom-modal" onClick={closeZoom}>
                    <div className="zoom-modal-content" onClick={(e) => e.stopPropagation()}>
                        {zoomedMedia[0].startsWith('data:image') ? (
                            <img src={zoomedMedia} alt="Zoomed" />
                        ) : (
                            <video controls>
                                <source src={zoomedMedia} type="video/mp4" />
                                Votre navigateur ne supporte pas la lecture des vidéos.
                            </video>
                        )}
                        <button className="close-zoom-btn" onClick={closeZoom}>Fermer</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GallerySection;
