import React from 'react';

import MailRecipientsTable from '../../components/admin-components/mail-recipients/admin--mail-recipients-manager';

/**
 * AdminMailRecipientsManager Component
 * 
 * This component displays the appointment actions management table in the admin panel.
 */
const AdminMailRecipientsManager = () => {

    // Render the appointment actions management table
    return (
        <MailRecipientsTable />
    );
};

export default AdminMailRecipientsManager;
