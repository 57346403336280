// cameraModule/hooks/useIsApp.js

import { Capacitor } from '@capacitor/core';
import { Filesystem } from '@capacitor/filesystem';

/**
 * Custom hook to determine if the application is running as a native app (iOS/Android).
 * It checks the platform and the availability of native-only plugins to avoid false positives from browser emulators.
 *
 * @returns {function} - A function that checks if the app is native and resolves to true or false.
 */
export const useIsApp = () => {
    /**
     * Function to check if the application is a native app.
     *
     * @returns {Promise<boolean>} - Returns a promise that resolves to true if the app is native, false otherwise.
     */
    const checkIfApp = async () => {
        const platform = Capacitor.getPlatform();

        const isNativeApp = platform === 'ios' || platform === 'android';
        if (isNativeApp) {
            try {
                // Check if a native-only plugin like Filesystem is available
                await Filesystem.getUri({ path: '' });
                return true; // The app is native
            } catch {
                return false; // The app is not native
            }
        } else {
            return false; // Not native
        }
    };

    return { checkIfApp }; // Return the check function
};