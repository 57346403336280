// Import from libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Import custom components
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';
import PaymentsService from '../../../../services/PaymentsService';

/**
 * AddNewPaymentModal renders a modal for adding an invoice payment.
 * The user selects the payment type, payment mode, and enters the payment amount.
 *
 * @param {boolean} open - Determines if the modal is open
 * @param {function} onClose - Handler to close the modal
 * @param {function} onAddPayment - Handler to add a new payment
 * @param {string} idFolder - The folder ID to add the payment to
 * @returns {JSX.Element} - Rendered add invoice payment modal component
 */
const AddNewPaymentModal = ({ open, onClose, onAddPayment, idFolder }) => {

    // State to store payment mode, amount, error, confirmation state, and payment date
    const [paymentMode, setPaymentMode] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [paymentDate, setPaymentDate] = useState('');

    /**
     * Handler for submitting the new payment
     * 
     * Checks if payment mode and amount are filled in.
     * Formats the current date and stores it in the state.
     * Opens the confirmation modal.
     */
    const handleAddPayment = () => {

        // Check if payment mode and amount are filled in
        if (!paymentMode || !amount) {
            setError("Veuillez remplir tous les champs.");
            return;
        }

        // Format the current date and store it in the state
        const formattedDate = new Date().toLocaleString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });

        // Store formatted date in state
        setPaymentDate(formattedDate);

        // Open the confirmation modal
        setIsConfirmationOpen(true);
    };

    // Confirm adding payment and close modals
    const handleConfirmPayment = async () => {
        try {
            // Call the addFolderPayment function from FoldersService
            const response = await PaymentsService.addFolderPayment({
                id_folder: idFolder,
                payment_mode: paymentMode,
                payment_date: paymentDate,
                amount: parseFloat(amount),
            });

            // If the response is empty, set an error message
            if (!response) {
                setError("Une erreur est survenue lors de l'ajout du paiement.");
                return;
            }
        } catch {
            setError(error?.message || "Une erreur inattendue est survenue");
        }

        // Call the onAddPayment function
        onAddPayment();

        // Reset form and close modals
        setPaymentMode('');
        setAmount('');
        setError(null);
        setIsConfirmationOpen(false);
        onClose();
    };

    // Cancel the confirmation modal and return to the form
    const handleCancelConfirmation = () => {
        setIsConfirmationOpen(false);
    };

    // Render the AddNewPaymentModal component
    return (
        <React.Fragment>
            {/* Main Add Invoice Payment Modal */}
            <CustomModal
                open={open}
                onClose={onClose}
                onCloseLabel="Annuler"
                title="Ajouter un règlement"
                handleSave={handleAddPayment}
                handleSaveLabel="Valider"
            >
                <div className="add-payment-modal-content">
                    {error && <InfoBox text={error} type="error" />}

                    {/* Select for payment mode */}
                    <div className="payment--input">
                        <label htmlFor="payment-mode">Mode de paiement</label>
                        <select
                            id="payment-mode"
                            value={paymentMode}
                            onChange={(e) => setPaymentMode(e.target.value)}
                        >
                            <option value="" disabled>-- Choisir un mode de paiement --</option>
                            <option value="Chèque">Chèque</option>
                            <option value="Espèce">Espèce</option>
                            <option value="CB">CB</option>
                        </select>
                    </div>

                    {/* Input for payment amount */}
                    <div className="payment--input">
                        <label htmlFor="amount">Montant (€)</label>
                        <input
                            id="amount"
                            type="number"
                            min="0"
                            step="0.01"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Saisir le montant"
                        />
                    </div>
                </div>
            </CustomModal>

            {/* Confirmation Modal */}
            {isConfirmationOpen && (
                <CustomModal
                    open={isConfirmationOpen}
                    onClose={handleCancelConfirmation}
                    onCloseLabel="Annuler"
                    title="Confirmer le paiement"
                    handleSave={handleConfirmPayment}
                    handleSaveLabel="Confirmer"
                >
                    <div className="confirmation-modal-content">
                        <InfoBox
                            text={
                                <React.Fragment>
                                    <p className="w-100">Vous êtes sur le point d'ajouter le paiement suivant :</p>
                                    <ul className="w-100">
                                        <li><strong>Mode de paiement :</strong> {paymentMode}</li>
                                        <li><strong>Montant :</strong> {amount} €</li>
                                        <li><strong>Date :</strong> {paymentDate}</li>
                                    </ul>
                                    <strong>Cette action ne pourra pas être annulée. Confirmez-vous ?</strong>
                                </React.Fragment>
                            }
                            type="warning"
                            disabledCloseBtn={true}
                        />
                    </div>
                </CustomModal>
            )}
        </React.Fragment>
    );
};

// Prop types for AddNewPaymentModal component
AddNewPaymentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAddPayment: PropTypes.func.isRequired,
    idFolder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AddNewPaymentModal;
